import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "services/network";

const initialState = {
  data: {
    user: {},
    token: null,
  },
};

export const loginRequest = createAsyncThunk(
  "account/login",
  async (payload, thunkAPI) => {
    try {
      const response = await User.login(payload);
      const { jwtToken } = response.data.data;
      sessionStorage.setItem("jwtToken", jwtToken);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const signUpRequest = createAsyncThunk(
  "account/signUp",
  async (payload, thunkAPI) => {
    try {
      const response = await User.register(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const resendActivationCodeRequest = createAsyncThunk(
  "account/resendActivationCode",
  async (payload, thunkAPI) => {
    try {
      const response = await User.resendActivationCode(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const verifyActivationCodeRequest = createAsyncThunk(
  "account/verifyActivationCode",
  async (payload, thunkAPI) => {
    try {
      const response = await User.verifyActivationCode(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const forgotPasswordRequest = createAsyncThunk(
  "account/forgotPassword",
  async (payload, thunkAPI) => {
    try {
      const response = await User.forgotPassword(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const resetPasswordRequest = createAsyncThunk(
  "account/resetPassword",
  async (payload, thunkAPI) => {
    try {
      const response = await User.resetPassword(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateUserDetailsRequest = createAsyncThunk(
  "account/updateUserDetails",
  async (payload, thunkAPI) => {
    try {
      const response = await User.updateDetails(
        thunkAPI.getState().account.data.user.userCode,
        payload
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const changePasswordRequest = createAsyncThunk(
  "account/changePassword",
  async (payload, thunkAPI) => {
    try {
      const { userCode } = thunkAPI.getState().account.data.user;
      const response = await User.changePassword(userCode, payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    logout: (state) => {
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [loginRequest.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
    [loginRequest.rejected]: (state) => {
      state.data = initialState.data;
    },
    [updateUserDetailsRequest.fulfilled]: (state, action) => {
      state.data.user = {
        ...state.data.user,
        ...action.payload.data,
      };
    },
  },
});

export const { logout } = accountSlice.actions;

export default accountSlice.reducer;
