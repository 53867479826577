import { useFormikContext } from "formik";
import { useEffect } from "react";

const FormikEffect = ({ field, callback }) => {
  const { values, touched } = useFormikContext();

  const fieldValue = values[field];
  const fieldTouched = touched[field];

  useEffect(() => {
    if (fieldValue && fieldTouched) {
      callback();
    }
  }, [fieldValue, fieldTouched, callback]);

  return null;
};

export default FormikEffect;
