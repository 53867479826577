import axios from "services/api";

export const fetchAllNotifications = (id) => {
  return axios.get(`/user/notifications/${id}`);
};

export const fetchNotificationSlots = (params) => {
  return axios.get("available-notification-times", {
    params: params,
  });
};

export const createNotification = (data) => {
  return axios.post("notifications", data);
};
