import React, { useEffect, useLayoutEffect, useState } from "react";
import "./styles.scss";
import Logo from "assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import {
  verifyActivationCodeRequest,
  resendActivationCodeRequest,
} from "state/slices/account";
import { ErrorMessage, Form, Formik } from "formik";
import produce from "immer";
import Button from "components/shared/forms/Button";
import ReactCodeInput from "react-code-input";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

const StyledLogo = styled.img`
  width: 120px;
`;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ConfirmOTP = ({ history }) => {
  const dispatch = useDispatch();
  const query = useQuery();

  useLayoutEffect(() => {
    document.title = "Africover - Confirm OTP";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      resendActivationCodeRequest({
        email: decodeURIComponent(query.get("email")),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [state, setState] = useState({
    isConfirmingOtp: false,
    isResendingOtp: false,
  });

  const initialValues = {
    otp: "",
  };

  const validationSchema = yup.object().shape({
    otp: yup.string().label("OTP").required(),
  });

  const handleVerifyActivationCode = async (formValues) => {
    const formPayload = {
      email: decodeURIComponent(query.get("email")),
      otp: formValues.otp,
    };

    setState(
      produce((draft) => {
        draft.isConfirmingOtp = true;
      })
    );

    const resultAction = await dispatch(
      verifyActivationCodeRequest(formPayload)
    );

    if (verifyActivationCodeRequest.fulfilled.match(resultAction)) {
      setState(
        produce((draft) => {
          draft.isConfirmingOtp = false;
        })
      );

      history.replace("/dashboard");

      toast.success(
        resultAction.payload?.message || "Account activated successfully."
      );
    } else {
      // formikHelpers.setFieldValue("otp", "", false);

      setState(
        produce((draft) => {
          draft.isConfirmingOtp = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  const resendActivationCode = async (e) => {
    e.preventDefault();

    setState(
      produce((draft) => {
        draft.isResendingOtp = true;
      })
    );

    const resultAction = await dispatch(
      resendActivationCodeRequest({
        email: decodeURIComponent(query.get("email")),
      })
    );

    if (resendActivationCodeRequest.fulfilled.match(resultAction)) {
      setState(
        produce((draft) => {
          draft.isResendingOtp = false;
        })
      );

      toast.success(
        resultAction.payload?.message || "Account activation code resent."
      );
    } else {
      setState(
        produce((draft) => {
          draft.isResendingOtp = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <div className="flex flex-row min-h-screen bg-gray-800 justify-center items-center font-nunito-sans">
      <CSSTransition
        in={true}
        appear={true}
        timeout={300}
        classNames="animate-translate--y"
        unmountOnExit
      >
        <div className="flex flex-col w-full justify-start md:justify-center p-3 md:p-0 md:w-2/3 lg:w-1/3 mt-10 md:mt-8 mb-16 md:mb-10">
          <div className="flex flex-row justify-start w-full">
            <Link to="/auth/login">
              <StyledLogo src={Logo} alt="" />
            </Link>
          </div>

          <div className="p-6 md:p-10 bg-white shadow rounded-xl mt-5">
            <h1 className="text-gray-900 text-lg font-bold">Confirm OTP</h1>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleVerifyActivationCode}
            >
              {({ handleSubmit, values, setFieldValue, isValid, errors }) => {
                return (
                  <Form className="w-full mt-5" onSubmit={handleSubmit}>
                    <div className="flex flex-col">
                      <ReactCodeInput
                        className="otp-fieldset self-center space-x-5"
                        type="text"
                        placeholder="*"
                        fields={4}
                        value={values.otp}
                        onChange={(value) => setFieldValue("otp", value)}
                      />
                      <ErrorMessage
                        name="otp"
                        component="span"
                        className="text-red-600 text-center text-xs mt-1 animate-fade-in-down"
                      />
                    </div>

                    <div className="flex flex-col mt-5">
                      <Button
                        type="submit"
                        title="Confirm"
                        variant="primary"
                        width="full"
                        loading={state.isConfirmingOtp}
                        disabled={!isValid}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>

            <div className="flex flex-row pt-5 justify-center">
              {!state.isResendingOtp ? (
                <span className="text-gray-600 text-sm">
                  Didn't receive code?{" "}
                  <a
                    href="#/"
                    className="text-teal-400 hover:text-teal-500 transition ease-in-out duration-300"
                    onClick={resendActivationCode}
                  >
                    RESEND OTP
                  </a>
                </span>
              ) : (
                <span className="text-gray-600 text-sm">
                  Resending OTP, Please wait...
                </span>
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default ConfirmOTP;
