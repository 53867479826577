import * as yup from "yup";
import _ from "lodash";

export const convertArrayToSelectOptions = (values) => {
  return values.map((value) => ({ label: value, value }));
};

export const getValidationSchema = (files) => {
  return yup
    .object()
    .shape(
      _.mapValues(_.keyBy(files, "field"), (item) =>
        yup.string().label(item.label).required()
      )
    );
};
