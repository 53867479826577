import React, {useEffect} from "react";
import "./styles.scss";
import Sidebar from "./components/Sidebar";
import PageProvider from "../../providers/PageContext";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import DashboardHome from "../main/DashboardHome";
import QuotableInsuranceList from "../main/QuotableInsuranceList";
import UserInsuranceClaims from "../main/UserInsuranceClaims";
import ReportClaim from "../main/ReportClaim";
import UserInsuranceList from "../main/UserInsuranceList";
import UserInsuranceDetails from "../main/UserInsuranceDetails";
import ProfileSettings from "../main/ProfileSettings";
import BuyInsurance from "../main/BuyInsurance";
import SsLag from "../main/SsLag";
import QuoteInsurance from "../main/QuoteInsurance";
import InsuranceList from "../main/InsuranceList";
import {useDispatch} from "react-redux";
import {fetchAllInsuranceCompaniesRequest} from "state/slices/insurances";
import KycForm from "../main/SsLag/KycForm";

const Dashboard = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllInsuranceCompaniesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <PageProvider>
      <div className="flex flex-row min-h-screen font-nunito-sans">
        <Sidebar />

        <Switch>
          <Route exact path={`${path}`} component={DashboardHome} />
          <Route exact path={`${path}/sslag`} component={SsLag} />
          <Route exact path={`${path}/buy-insurance`} component={InsuranceList} />
          <Route exact path={`${path}/buy-insurance/type/:businessClassId`} component={BuyInsurance} />
          <Route exact path={`${path}/get-quote`} component={QuotableInsuranceList} />
          <Route exact path={`${path}/get-quote/type/:businessClassId`} component={QuoteInsurance} />
          <Route exact path={`${path}/claims`} component={UserInsuranceClaims} />
          <Route exact path={`${path}/claims/report`} component={ReportClaim} />
          <Route exact path={`${path}/insurances`} component={UserInsuranceList} />
          <Route exact path={`${path}/insurances/:coverId/details`} component={UserInsuranceDetails} />
          <Route path={`${path}/profile/settings`} component={ProfileSettings} />
          <Route path={`${path}/sslag/kyc-form`} component={KycForm} />
        </Switch>
      </div>
    </PageProvider>
  );
};

export default Dashboard;
