import * as RemixIcon from "react-icons/ri";
import * as FontAwesomeIcon from "react-icons/fa";
import * as MaterialIcon from "react-icons/md";
import * as GameIcon from "react-icons/gi";
import * as AntIcon from "react-icons/ai";

const productCategoryIcons = {
  AG: RemixIcon.RiPlantFill,
  AI: RemixIcon.RiPlaneFill,
  PI: FontAwesomeIcon.FaAccusoft,
  ATM: MaterialIcon.MdCreditCard,
  GIT: MaterialIcon.MdLocalGroceryStore,
  SAFETYPLUS:MaterialIcon.MdHealing,
  FG: MaterialIcon.MdHttps,
  AR: MaterialIcon.MdSecurity,
  TERMASSURANCE: FontAwesomeIcon.FaUserCheck,
  MORTGAGEPROTECTION: MaterialIcon.MdAccountBalance,
  TRAVEL: RemixIcon.RiPlaneFill,
  HOUSEHOLDERS: MaterialIcon.MdHome,
  H0USEOWNERS: MaterialIcon.MdHome,
  BOND: FontAwesomeIcon.FaHockeyPuck,
  BURGLARY: FontAwesomeIcon.FaLock,
  FIRE: FontAwesomeIcon.FaGripfire,
  GA: FontAwesomeIcon.FaCarCrash,
  HEALTH: FontAwesomeIcon.FaHospitalAlt,
  LIFE: FontAwesomeIcon.FaUserCheck,
  TERM: FontAwesomeIcon.FaUserCheck,
  MARINE: RemixIcon.RiShip2Fill,
  MH: RemixIcon.RiShipFill,
  MOT: MaterialIcon.MdDirectionsCar,
  THIRDPARTY: MaterialIcon.MdDirectionsCar,
  thirdpartycom: MaterialIcon.MdDirectionsCar,
  PENSION: RemixIcon.RiCoinsFill,
  RA: RemixIcon.RiBankCard2Fill,
  Test: RemixIcon.RiTestTubeFill,
  TPL: RemixIcon.RiShieldCheckFill,
  OTHERS: FontAwesomeIcon.FaMoneyBill,
  MATERIALDAMAGE: GameIcon.GiDamagedHouse,
  LIABILITY: GameIcon.GiPodiumThird,
  EDUCATIONALTRIPS: AntIcon.AiOutlineUsergroupAdd,
  ALLRISKS: MaterialIcon.MdSecurity
};

export default productCategoryIcons;
