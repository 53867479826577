import React, { useContext } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
import { PageContext } from "components/providers/PageContext";

const NavMenuItem = ({ item, item: { icon: IconComponent } }) => {
  const { closeSidebar } = useContext(PageContext);

  let match = useRouteMatch({
    path: item.to,
    exact: item.activeOnlyWhenExact,
  });

  return (
    <Link to={item.to} onClick={closeSidebar}>
      <li
        className={classNames({
          "flex flex-col justify-between items-center px-8 py-4 my-2 ml-3 rounded-l-full text-white transition ease-in-out duration-300": true,
          "bg-orange-400": match,
        })}
      >
        <div className="flex flex-row w-full justify-between items-center">
          <div className="flex flex-row items-center">
            <span className="w-6">
              <IconComponent size={20} />
            </span>
            <span className="text-base ml-4">{item.title}</span>
          </div>
        </div>
      </li>
    </Link>
  );
};

export default NavMenuItem;
