import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import TextInput from "components/shared/forms/TextInput";
import Button from "components/shared/forms/Button";
import produce from "immer";
import { changePasswordRequest } from "state/slices/account";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";

const CustomTextInput = (props) => <TextInput variant="white" {...props} />;

const ChangePassword = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    isUpdating: false,
  });

  const initialValues = {
    password: "",
    passwordConfirmation: "",
  };

  const validationSchema = yup.object().shape({
    password: yup.string().label("Password").required(),
    passwordConfirmation: yup
      .string()
      .label("Password confirmation")
      .oneOf([yup.ref("password"), null], "Password confirmation must match")
      .required(),
  });

  const handleChangePassword = async (formValues, formikHelpers) => {
    const formPayload = {
      newPassword: formValues.password,
      confirm_newPassword: formValues.passwordConfirmation,
    };

    setState(
      produce((draft) => {
        draft.isUpdating = true;
      })
    );

    const resultAction = await dispatch(changePasswordRequest(formPayload));

    if (changePasswordRequest.fulfilled.match(resultAction)) {
      formikHelpers.resetForm();

      setState(
        produce((draft) => {
          draft.isUpdating = false;
        })
      );

      toast.success(
        resultAction.payload?.message || "Password changed successfully."
      );
    } else {
      setState(
        produce((draft) => {
          draft.isUpdating = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <CSSTransition
      in={true}
      appear={true}
      timeout={300}
      classNames="animate-translate--y"
      unmountOnExit
    >
      <div>
        <span className="text-xl text-gray-900 font-bold">Change Password</span>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleChangePassword}
        >
          {({ handleSubmit, isValid }) => {
            return (
              <Form className="w-full xl:w-3/5 mt-5" onSubmit={handleSubmit}>
                <div className="-mx-3 lg:flex mt-0 lg:mt-0">
                  <div className="lg:w-full px-3 mt-6 lg:mt-0">
                    <div className="flex flex-col">
                      <label
                        htmlFor="password"
                        className="text-sm text-gray-600 font-semibold"
                      >
                        Password
                      </label>
                      <Field
                        component={CustomTextInput}
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                </div>
                <div className="-mx-3 lg:flex mt-0 lg:mt-6">
                  <div className="lg:w-full px-3 mt-6 lg:mt-0">
                    <div className="flex flex-col">
                      <label
                        htmlFor="passwordConfirmation"
                        className="text-sm text-gray-600 font-semibold"
                      >
                        Confirm Password
                      </label>
                      <Field
                        component={CustomTextInput}
                        type="password"
                        name="passwordConfirmation"
                        id="passwordConfirmation"
                        placeholder="Confirm Password"
                      />
                    </div>
                  </div>
                </div>
                <div className="-mx-3 lg:flex mt-0 lg:mt-8">
                  <div className="flex flex-row justify-end lg:w-full px-3 mt-8 lg:mt-0">
                    <Button
                      type="submit"
                      title="Update"
                      loadingTitle="Updating..."
                      variant="primary"
                      size="md"
                      width="auto"
                      loading={state.isUpdating}
                      disabled={!isValid}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </CSSTransition>
  );
};

export default ChangePassword;
