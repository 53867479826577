import React, { useContext } from "react";
import { PageContext } from "components/providers/PageContext";
import Header from "./components/Header";
import ErrorState from "./components/ErrorState";

const PageContent = ({
  headerTitle,
  bg,
  errorMessage,
  onRefresh,
  children,
}) => {
  const { closeSidebar } = useContext(PageContext);

  return (
    <div
      className={`flex flex-col bg-${bg || "white"} justify-start page-content`}
      onMouseUp={closeSidebar}
    >
      <Header title={headerTitle} />

      {!errorMessage ? (
        children
      ) : (
        <div className="flex flex-row w-full h-full pb-8">
          <ErrorState onRetry={onRefresh} errorMessage={errorMessage} />
        </div>
      )}
    </div>
  );
};

export default PageContent;
