import axios from "services/api";

export const startPayment = (data) => {
  return axios.post("/payment/start-payment", data);
};

export const verifyPayment = (data) => {
  return axios.post(`/payment/verify-payment/${data.transactionReference}`, {
    paymentcode: data.paymentCode,
  });
};
