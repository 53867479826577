import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Payment } from "services/network";
import { fetchAllInsurancePoliciesByUserIdRequest } from "./insurances";

const initialState = {};

export const startPaymentRequest = createAsyncThunk(
  "payment/startPayment",
  async (payload, thunkAPI) => {
    try {
      const response = await Payment.startPayment({
        email: thunkAPI.getState().account.data.user.email,
        amount: payload,
      });
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const verifyPaymentRequest = createAsyncThunk(
  "payment/verifyPayment",
  async (payload, thunkAPI) => {
    try {
      const response = await Payment.verifyPayment(payload);

      const userId = thunkAPI.getState().account.data.user.userCode;
      thunkAPI.dispatch(fetchAllInsurancePoliciesByUserIdRequest(userId));

      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  // extraReducers: {
  //   [verifyPaymentRequest.fulfilled]: (state, action) => {
  //     state.insurancePolicies = action.payload.data;
  //   },
  // },
});

export default paymentsSlice.reducer;
