import axios from "services/api";

export const login = (data) => {
  return axios.post("/user/login", data);
};

export const register = (data) => {
  return axios.post("/user", data);
};

export const resendActivationCode = (data) => {
  return axios.put(`/user/resend-activation-code/email/${data.email}`);
};

export const verifyActivationCode = (params) => {
  return axios.get(
    `/user/verify-activation-code/${params.email}/${params.otp}`
  );
};

export const forgotPassword = (data) => {
  return axios.post(`/user/start-forget-password/${data.email}`);
};

export const resetPassword = (data) => {
  return axios.post(
    `/user/complete-forget-password/${data.email}/${data.code}`,
    {
      newPassword: data.newPassword,
      confirm_newPassword: data.confirm_newPassword,
    }
  );
};

export const updateDetails = (id, data) => {
  return axios.put(`/user/${id}`, data);
};

export const changePassword = (id, data) => {
  return axios.put(`/user/change-password/${id}`, data);
};
