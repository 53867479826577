import React, { Fragment } from "react";
import ReactSelect from "react-select";

const Select = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  ...props
}) => {
  const hasError = touched[field.name] && errors[field.name];

  const handleBlur = (e) => {
    e.target.name = field.name;
    field.onBlur(e);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "0.895rem",
      color: state.isSelected ? "#4b4b4b" : "#4b4b4b",
      backgroundColor: state.isSelected ? "#f4f4f4" : "#ffffff",
      borderRadius: 5,
      boxSizing: "border-box",
      margin: "5px 10px",
      width: "calc(100% - 20px)",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f4f4f4",
      },
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "0.895rem",
      border: "1px solid rgba(33,33,33,.14)",
      borderBottomRightRadius: 2,
      borderBottomLeftRadius: 2,
      paddingTop: 5,
      boxShadow: "0 0.5px 0.2px rgba(0, 0, 0, .15)",
      boxSizing: "border-box",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      border: `solid 1px ${state.isFocused ? "#faae54" : "#e4e4e7"}`,
      padding: "0.35rem 0.75rem",
      fontSize: "0.895rem",
      color: "#ff9900",
      margin: "0.5rem 0 0 0",
      boxShadow: "none",
      minHeight: "auto",
      cursor: "pointer",
      "&:hover": {
        border: "solid 1px #e4e4e7",
      },
    }),
  };

  return (
    <Fragment>
      <ReactSelect
        styles={customStyles}
        name={field.name}
        onChange={(value) => setFieldValue(field.name, value)}
        value={field.value}
        onBlur={handleBlur}
        {...props}
      />
      {hasError && (
        <span className="text-red-600 text-xs mt-1 animate-fade-in-down">
          {errors[field.name]}
        </span>
      )}
    </Fragment>
  );
};

export default Select;
