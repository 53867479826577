import React, { Fragment } from "react";
import ReactDatePicker from "react-datepicker";

const DatePicker = ({
  field,
  form: { touched, errors, setFieldValue },
  variant,
  ...props
}) => {
  const hasError = touched[field.name] && errors[field.name];

  return (
    <Fragment>
      <ReactDatePicker
        autoComplete="off"
        showPopperArrow={false}
        className={`appearance-none w-full w-100 mt-2 py-2.5 px-3 bg-${
          variant || "gray-100"
        } rounded-md border border-gray-200 text-sm text-gray-900 focus:border-orange-300 focus:outline-none transition ease-in-out duration-300`}
        name={field.name}
        placeholderText={props.placeholder}
        selected={field.value}
        onChangeRaw={(e) => e.preventDefault()}
        onChange={(value) => setFieldValue(field.name, value)}
        onBlur={field.onBlur}
        calendarClassName="border-0 shadow font-nunito-sans text-sm"
        dateFormat="yyyy/MM/dd"
        dropdownMode="select"
        showMonthDropdown={true}
        showYearDropdown={true}
        {...props}
      />

      {hasError && (
        <span className="text-red-600 text-xs mt-1 animate-fade-in-down">
          {errors[field.name]}
        </span>
      )}
    </Fragment>
  );
};

export default DatePicker;
