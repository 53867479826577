import React, { createContext, useState } from "react";

const PageContext = createContext({});

const PageProvider = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const openSidebar = () => setShowSidebar(true);
  const closeSidebar = () => setShowSidebar(false);

  const value = { showSidebar, openSidebar, closeSidebar };

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};
export { PageContext };
export default PageProvider;
