import React, { useContext, useEffect } from "react";
import { Form, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import CustomerDetailsFormSection from "./components/CustomerDetailsFormSection";
import InsuranceDetailsFormSection from "./components/InsuranceDetailsFormSection";
import UploadDocumentsFormSection from "./components/UploadDocumentsFormSection";
import { CreateInsuranceContext } from "contexts/CreateInsuranceContext";

const BuyInsuranceForm = () => {
  const { currentStep, businessClassId } = useContext(CreateInsuranceContext);
  const { handleSubmit, validateForm } = useFormikContext();

  useEffect(() => {
    (() => validateForm())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const productCategory =
    useSelector((state) =>
      state.insurances.insuranceCategories.find((category) => {
        return category.BusinessClassID.toLowerCase() === businessClassId;
      })
    ) || {};

  const formSections = [
    {
      title: "Customer Details",
      component: CustomerDetailsFormSection,
    },
    {
      title: "Insurance Details",
      component: InsuranceDetailsFormSection,
    },
    {
      title: "Upload Documents",
      component: UploadDocumentsFormSection,
    },
  ];

  const FormSectionComponent = formSections[currentStep].component;

  return (
    <div className="flex flex-row w-full pb-8">
      <div className="flex flex-col w-full">
        <div className="flex flex-col w-full px-10 pt-12">
          <div className="flex flex-col">
            <div className="bg-gray-800 text-white text-lg text-center font-semibold p-4 shadow rounded-t-xl">
              {_.startCase(
                (
                  productCategory.BusinessClassName || "Loading..."
                ).toLowerCase()
              )}
            </div>
            <div className="flex flex-row justify-center lg:justify-start border border-t-0 border-gray-200">
              <div className="flex flex-row -mx-5 pt-10">
                {formSections.map(
                  ({ title, component: sectionComponent }, index) => (
                    <div
                      key={title}
                      className={`px-3 lg:px-5 mx-1 lg:mx-5 text-gray-600 text-center tab-title ${
                        index === currentStep ? "active" : ""
                      }`}
                    >
                      {title}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          <Form className="flex flex-col w-full mt-10" onSubmit={handleSubmit}>
            <FormSectionComponent />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BuyInsuranceForm;
