import React from "react";
import EmptySvg from "./components/EmptySvg";
import { Link } from "react-router-dom";

const EmptyState = () => (
  <div className="flex flex-col w-full items-center justify-center lg:w-full animate-translate--y-appear-done animate-translate--y-enter-done">
    <div className="flex flex-col w-2/3 items-center pb-20">
      <EmptySvg className="w-64 h-64 rounded-full" />
      <div className="flex flex-col justify-center text-center">
        <div className="text-lg text-gray-700 pt-5 font-semibold">
          Nothing to see here yet
        </div>
        <div className="text-base text-gray-500 pt-2">
          You don't have any reported insurance claims yet.
        </div>
        <div className="flex flex-row justify-center items-center pt-5">
          <Link
            to="/dashboard/claims/report"
            className="inline-flex items-center justify-center text-white py-2.5 px-8 text-sm font-semibold rounded-md bg-red-600 hover:bg-gray-900 focus:outline-none disabled:opacity-50 transition ease-in-out duration-300"
          >
            Report Claim
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default EmptyState;
