import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import * as HeroIcon from "react-icons/hi";
import * as MaterialIcon from "react-icons/md";
import { PageContext } from "components/providers/PageContext";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllNotificationsRequest } from "state/slices/notifications";
import { logout } from "state/slices/account";
import { Transition } from "@tailwindui/react";

const Header = ({ title }) => {
  const dispatch = useDispatch();
  const { openSidebar } = useContext(PageContext);
  const notifications = useSelector((state) => state.notifications.data);
  const user = useSelector((state) => state.account.data.user);
  const [isAccountDropdownOpen, setShowAccountDropdownOpen] = useState(false);
  const container = useRef(null);

  useEffect(() => {
    dispatch(fetchAllNotificationsRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!container?.current?.contains(event.target)) {
        if (!isAccountDropdownOpen) return;
        setShowAccountDropdownOpen(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);
    return () => window.removeEventListener("click", handleOutsideClick);
  }, [isAccountDropdownOpen, container]);

  useEffect(() => {
    const handleEscape = (event) => {
      if (isAccountDropdownOpen && event.key !== "Escape") {
        return false;
      }

      setShowAccountDropdownOpen(false);
    };

    document.addEventListener("keyup", handleEscape);
    return () => document.removeEventListener("keyup", handleEscape);
  }, [isAccountDropdownOpen]);

  const toggleAccountDropdownOpen = () =>
    setShowAccountDropdownOpen(!isAccountDropdownOpen);

  return (
    <div className="flex flex-row justify-between items-center bg-white shadow w-full px-4 sm:px-10 py-4 z-10">
      <div className="flex flex-row">
        <div
          className="flex flex-row md:hidden items-center mr-8 text-black hover:text-gray-700 focus:text-gray-700 cursor-pointer transition ease-in-out duration-300"
          onClick={openSidebar}
        >
          <HeroIcon.HiMenu className="text-3xl" />
        </div>
        <h1 className="text-lg md:text-xl font-bold text-gray-900 font-medium">
          {title}
        </h1>
      </div>
      <div className="flex flex-row items-center space-x-4 md:space-x-8">
        <Link
          to="/dashboard/profile/settings/notifications"
          className="relative"
        >
          {notifications.length > 0 && (
            <Fragment>
              <span
                className="flex h-4 w-4 absolute"
                style={{ top: "-6px", right: "-4px" }}
              >
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-300 opacity-75" />
                <span className="relative inline-flex items-center justify-center rounded-full h-4 w-4 bg-orange-400 text-white text-2xs">
                  {notifications.length}
                </span>
              </span>
            </Fragment>
          )}
          <MaterialIcon.MdNotifications className="text-xl text-gray-500" />
        </Link>

        <div
          ref={container}
          className="relative"
          onClick={toggleAccountDropdownOpen}
        >
          <button
            type="button"
            className="relative inline-flex items-center justify-center w-10 h-10 mx-auto rounded-full bg-teal-400 hover:opacity-90 font-semibold text-white appearance-none focus:outline-none transition ease-out duration-100"
          >
            <div>{`${user.firstname.charAt(0)}${user.lastname.charAt(0)}`}</div>
          </button>
          <Transition
            className="origin-top-right absolute top-10 right-0 mt-2 w-56 rounded-md shadow bg-white divide-y divide-gray-100"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
            show={isAccountDropdownOpen}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95 transition ease-out duration-100"
            enterTo="transform opacity-100 scale-100 transition ease-out duration-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100 transition ease-in duration-75"
            leaveTo="transform opacity-0 scale-95 transition ease-in duration-75"
          >
            <div className="py-1">
              <Link
                to="/dashboard/profile/settings/edit-profile"
                className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                Edit Profile
              </Link>
              <Link
                to="/dashboard/profile/settings/change-password"
                className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                Change Password
              </Link>
            </div>
            <div className="py-1">
              <button
                type="button"
                className="appearance-none focus:outline-none w-full text-left px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                onClick={() => dispatch(logout())}
              >
                Logout
              </button>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
};

export default Header;
