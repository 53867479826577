import React from "react";

const SummaryChartSkeleton = () => (
  <div className="flex flex-col w-full">
    <div className="flex flex-row flex-wrap justify-between items-center px-10">
      <div className="flex flex-row flex-wrap w-full lg:w-auto mt-5 lg:mt-0 space-x-5">
        <div className="flex flex-row w-28 h-6 items-center px-5 py-2 bg-gray-200 rounded-sm animate-pulse" />
      </div>
    </div>

    <div className="flex flex-row w-full px-10 py-6">
      <div className="flex flex-row justify-center items-end w-3/5 space-x-5">
        <div className="w-32 h-32 bg-gray-200 animate-pulse" />
        <div className="w-32 h-52 bg-gray-200 animate-pulse" />
        <div className="w-32 h-20 bg-gray-200 animate-pulse" />
        <div className="w-32 h-32 bg-gray-200 animate-pulse" />
      </div>
      <div className="flex flex-row justify-center items-center w-2/5">
        <div className="flex flex-col justify-center items-start space-y-3">
          <div className="flex flex-row justify-start items-center">
            <div className="flex flex-row justify-center items-center h-6 w-6 p-2 rounded-lg bg-gray-200 mr-5 animate-pulse" />
            <div className="h-5 w-28 bg-gray-200 animate-pulse" />
          </div>

          <div className="flex flex-row justify-start items-center">
            <div className="flex flex-row justify-center items-center h-6 w-6 p-2 rounded-lg bg-gray-200 mr-5 animate-pulse" />
            <div className="h-5 w-20 bg-gray-200 animate-pulse" />
          </div>

          <div className="flex flex-row justify-start items-center">
            <div className="flex flex-row justify-center items-center h-6 w-6 p-2 rounded-lg bg-gray-200 mr-5 animate-pulse" />
            <div className="h-5 w-24 bg-gray-200 animate-pulse" />
          </div>

          <div className="flex flex-row justify-start items-center">
            <div className="flex flex-row justify-center items-center h-6 w-6 p-2 rounded-lg bg-gray-200 mr-5 animate-pulse" />
            <div className="h-5 w-28 bg-gray-200 animate-pulse" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SummaryChartSkeleton;
