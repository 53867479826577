import React, {useEffect} from "react";
import { Provider as StoreProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "state/store";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "components/shared/PrivateRoute";
import Login from "components/pages/auth/Login";
import Register from "components/pages/auth/Register";
import ConfirmOTP from "components/pages/auth/ConfirmOTP";
import ForgotPassword from "components/pages/auth/ForgotPassword";
import ResetPassword from "components/pages/auth/ResetPassword";
import Dashboard from "components/pages/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";

const App = () => {

  useEffect(() => {
    const s1 = document.createElement("script");

    s1.async = false;
    s1.src = `${process.env.REACT_APP_TAWKTO_URL}`;
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");

    const s0 = document.getElementsByTagName("script")[0];
    s0.parentNode.insertBefore(s1, s0);

    return () => {
      document.body.removeChild(s1);
    };
  }, []);

  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/auth/login" component={Login} />
            <Route exact path="/auth/sign-up" component={Register} />
            <Route exact path="/auth/confirm-otp" component={ConfirmOTP} />
            <Route exact path="/auth/forgot-password" component={ForgotPassword} />
            <Route exact path="/auth/reset-password" component={ResetPassword}/>
            <PrivateRoute path="/dashboard" component={Dashboard} />
            
            <Redirect to="/auth/login"/>
          </Switch>

          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            toastClassName="px-2 shadow-sm rounded-md text-sm"
          />
        </BrowserRouter>
      </PersistGate>
    </StoreProvider>
  );
};

export default App;
