import React, { Fragment } from "react";
import NumberFormat from "react-number-format";

const NumberFormatInput = ({
  field,
  form: { setFieldValue, handleBlur, touched, errors },
  variant,
  ...props
}) => {
  const hasError = touched[field.name] && errors[field.name];

  return (
    <Fragment>
      <NumberFormat
        hintText={field.label}
        name={field.name}
        thousandSeparator={true}
        prefix="₦"
        onValueChange={({ value }) => setFieldValue(field.name, value)}
        onBlur={handleBlur}
        className={`appearance-none w-100 mt-2 py-2.5 px-3 bg-${
          variant || "gray-100"
        } rounded-md border border-gray-200 text-sm text-gray-900 focus:border-orange-300 disabled:bg-gray-100 focus:outline-none transition ease-in-out duration-300`}
        {...props}
      />

      {hasError && (
        <span className="text-red-600 text-xs mt-1 animate-fade-in-down">
          {errors[field.name]}
        </span>
      )}
    </Fragment>
  );
};

export default NumberFormatInput;
