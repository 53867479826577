import React from "react";
import Modal from "react-modal";
import * as FeatherIcon from "react-icons/fi";
import { useHistory, useParams } from "react-router-dom";

const GetQuoteNoticeModal = ({ isOpen, onRequestClose, ...props }) => {
  const history = useHistory();
  const { businessClassId } = useParams();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 overflow-y-auto"
      style={{ overlay: { backgroundColor: null, zIndex: 50, border: "none" } }}
      closeTimeoutMS={200}
      ariaHideApp={false}
      {...props}
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-50" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
        &#8203;
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-sm transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full px-10 py-14"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <button
            type="button"
            className="inline-flex flex-grow-0 justify-center items-center absolute top-2 right-2 bg-orange-400 text-white text-base leading-6 font-medium h-6 w-6 rounded-full hover:bg-orange-500 focus:outline-none transition ease-in-out duration-300"
            onClick={onRequestClose}
          >
            <FeatherIcon.FiX size={16} className="text-white" />
          </button>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                className="text-lg text-center leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Available on Get-Quote
              </h3>
              <div className="mt-2 text-center">
                <p className="text-sm leading-5 text-gray-500">
                  This product is available on Get-Quote.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row justify-center space-x-3">
            <span className="flex w-full rounded-md shadow-sm sm:w-auto">
              <button
                type="button"
                className="inline-flex w-full justify-center items-center bg-orange-400 text-white text-base leading-6 font-medium py-2 px-4 rounded-md hover:bg-orange-500 focus:outline-none transition ease-in-out duration-300"
                onClick={() =>
                  history.push(`/dashboard/get-quote/type/${businessClassId}`)
                }
              >
                Go to Quote
              </button>
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GetQuoteNoticeModal;
