import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import Button from "components/shared/forms/Button";
import produce from "immer";
import { updateUserDetailsRequest } from "state/slices/account";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import DatePicker from "components/shared/forms/DatePicker";
import { convertArrayToSelectOptions } from "utils";
import Select from "components/shared/forms/Select";
import WhiteBackgroundTextInput from "components/shared/forms/WhiteBackgroundTextInput";
import moment from "moment";
import parseIsoDate from "yup/lib/util/isodate";

const genderOptions = convertArrayToSelectOptions(["Male", "Female"]);

const EditProfile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.account.data);

  const [state, setState] = useState({
    isUpdating: false,
  });

  const initialValues = {
    firstname: user.firstname || "",
    lastname: user.lastname || "",
    othername: user.othername || "",
    dob: user.dob ? parseIsoDate(user.dob) : "",
    email: user.email || "",
    phone: user.phone || "",
    sex: user.sex ? { label: user.sex, value: user.sex } : "",
    address: user.address || "",
    city: user.city || "",
    state: user.state || "",
    country: user.country || "",
  };

  const validationSchema = yup.object().shape({
    firstname: yup.string().label("First name").required(),
    lastname: yup.string().label("Last name").required(),
    othername: yup.string().label("Other name").required(),
    dob: yup.string().label("Date of birth").required(),
    email: yup.string().label("Email address").required(),
    phone: yup.string().label("Phone number").required(),
    sex: yup.string().label("Gender").required(),
    address: yup.string().label("Address").required(),
    city: yup.string().label("City").required(),
    state: yup.string().label("State").required(),
    country: yup.string().label("Country").required(),
  });

  const handleSaveProfile = async (formValues) => {
    setState(
      produce((draft) => {
        draft.isUpdating = true;
      })
    );

    const formPayload = {
      othername: formValues.othername,
      dob: moment(formValues.dob).format("YYYY/MM/DD"),
      phone: formValues.phone,
      city: formValues.city,
      state: formValues.state,
      address: formValues.address,
      country: formValues.country,
      sex: formValues.sex.value,
    };

    const resultAction = await dispatch(updateUserDetailsRequest(formPayload));

    if (updateUserDetailsRequest.fulfilled.match(resultAction)) {
      setState(
        produce((draft) => {
          draft.isUpdating = false;
        })
      );

      toast.success(
        resultAction.payload?.message || "User details updated successfully."
      );
    } else {
      setState(
        produce((draft) => {
          draft.isUpdating = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <CSSTransition
      in={true}
      appear={true}
      timeout={300}
      classNames="animate-translate--y"
      unmountOnExit
    >
      <div>
        <span className="text-xl text-gray-900 font-bold">Edit Profile</span>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSaveProfile}
        >
          {({ handleSubmit, isValid }) => {
            return (
              <Form className="w-full xl:w-4/5 mt-5" onSubmit={handleSubmit}>
                <div className="-mx-3 lg:flex mt-0 lg:mt-0">
                  <div className="lg:w-full px-3 mt-6 lg:mt-0">
                    <div className="flex flex-row justify-center items-center">
                      <div className="relative inline-block w-auto">
                        <div className="inline-flex justify-center items-center w-28 h-28 rounded-full text-3xl bg-gray-800 font-semibold text-white">
                          <div>
                            {`${user.firstname.charAt(0)}${user.lastname.charAt(
                              0
                            )}`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="-mx-3 lg:flex mt-0 lg:mt-6">
                  <div className="lg:w-1/2 px-3 mt-6 lg:mt-0">
                    <div className="flex flex-col">
                      <label
                        htmlFor="firstName"
                        className="text-sm text-gray-600 font-semibold"
                      >
                        First Name
                      </label>
                      <Field
                        component={WhiteBackgroundTextInput}
                        type="text"
                        name="firstname"
                        id="firstName"
                        placeholder="First Name"
                      />
                    </div>
                  </div>
                  <div className="lg:w-1/2 px-3 mt-6 lg:mt-0">
                    <div className="flex flex-col">
                      <label
                        htmlFor="lastName"
                        className="text-sm text-gray-600 font-semibold"
                      >
                        Last Name
                      </label>
                      <Field
                        component={WhiteBackgroundTextInput}
                        type="text"
                        name="lastname"
                        id="lastName"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                </div>
                <div className="-mx-3 lg:flex mt-0 lg:mt-6">
                  <div className="lg:w-1/2 px-3 mt-6 lg:mt-0">
                    <div className="flex flex-col">
                      <label
                        htmlFor="otherName"
                        className="text-sm text-gray-600 font-semibold"
                      >
                        Other Name
                      </label>
                      <Field
                        component={WhiteBackgroundTextInput}
                        type="text"
                        name="othername"
                        id="otherName"
                        placeholder="Other Name"
                      />
                    </div>
                  </div>
                  <div className="lg:w-1/2 px-3 mt-6 lg:mt-0">
                    <div className="flex flex-col">
                      <label
                        htmlFor="dateOfBirth"
                        className="text-sm text-gray-600 font-semibold"
                      >
                        Date of Birth
                      </label>
                      <Field
                        component={DatePicker}
                        name="dob"
                        id="dateOfBirth"
                        variant="white"
                        placeholder="Date of Birth"
                      />
                    </div>
                  </div>
                </div>

                <div className="-mx-3 lg:flex mt-0 lg:mt-6">
                  <div className="lg:w-1/2 px-3 mt-6 lg:mt-0">
                    <div className="flex flex-col">
                      <label
                        htmlFor="emailAddress"
                        className="text-sm text-gray-600 font-semibold"
                      >
                        Email Address
                      </label>
                      <Field
                        component={WhiteBackgroundTextInput}
                        type="emailAddress"
                        name="email"
                        id="emailAddress"
                        placeholder="Email Address"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="lg:w-1/2 px-3 mt-6 lg:mt-0">
                    <div className="flex flex-col">
                      <label
                        htmlFor="phoneNumber"
                        className="text-sm text-gray-600 font-semibold"
                      >
                        Phone Number
                      </label>
                      <Field
                        component={WhiteBackgroundTextInput}
                        type="text"
                        name="phone"
                        id="phoneNumber"
                        placeholder="Phone Number"
                      />
                    </div>
                  </div>
                </div>

                <div className="-mx-3 lg:flex mt-0 lg:mt-6">
                  <div className="lg:w-1/2 px-3 mt-6 lg:mt-0">
                    <div className="flex flex-col">
                      <label
                        htmlFor="gender"
                        className="text-sm text-gray-600 font-semibold"
                      >
                        Gender
                      </label>
                      <Field
                        component={Select}
                        name="sex"
                        id="gender"
                        placeholder="Please select a gender"
                        options={genderOptions}
                      />
                    </div>
                  </div>
                  <div className="lg:w-1/2 px-3 mt-6 lg:mt-0">
                    <div className="flex flex-col">
                      <label
                        htmlFor="address"
                        className="text-sm text-gray-600 font-semibold"
                      >
                        Address
                      </label>
                      <Field
                        component={WhiteBackgroundTextInput}
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Address"
                      />
                    </div>
                  </div>
                </div>

                <div className="-mx-3 lg:flex mt-0 lg:mt-6">
                  <div className="lg:w-1/2 px-3 mt-6 lg:mt-0">
                    <div className="flex flex-col">
                      <label
                        htmlFor="city"
                        className="text-sm text-gray-600 font-semibold"
                      >
                        City
                      </label>
                      <Field
                        component={WhiteBackgroundTextInput}
                        type="text"
                        name="city"
                        id="city"
                        placeholder="City"
                      />
                    </div>
                  </div>

                  <div className="lg:w-1/2 px-3 mt-6 lg:mt-0">
                    <div className="flex flex-col">
                      <label
                        htmlFor="state"
                        className="text-sm text-gray-600 font-semibold"
                      >
                        State
                      </label>
                      <Field
                        component={WhiteBackgroundTextInput}
                        type="text"
                        name="state"
                        id="state"
                        placeholder="State"
                      />
                    </div>
                  </div>
                </div>

                <div className="-mx-3 lg:flex mt-0 lg:mt-6">
                  <div className="lg:w-full px-3 mt-6 lg:mt-0">
                    <div className="flex flex-col">
                      <label
                        htmlFor="country"
                        className="text-sm text-gray-600 font-semibold"
                      >
                        Country
                      </label>
                      <Field
                        component={WhiteBackgroundTextInput}
                        type="text"
                        name="country"
                        id="country"
                        placeholder="Country"
                      />
                    </div>
                  </div>
                </div>

                <div className="-mx-3 lg:flex mt-0 lg:mt-8">
                  <div className="flex flex-row justify-end lg:w-full px-3 mt-8 lg:mt-0">
                    <Button
                      type="submit"
                      title="Update"
                      loadingTitle="Updating..."
                      variant="primary"
                      size="md"
                      width="auto"
                      loading={state.isUpdating}
                      disabled={!isValid}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </CSSTransition>
  );
};

export default EditProfile;
