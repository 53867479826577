import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { File } from "services/network";

const initialState = {
  data: {},
};

export const uploadFileRequest = createAsyncThunk(
  "files/uploadFile",
  async (payload, thunkAPI) => {
    try {
      const response = await File.uploadFile(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {},
});

export default filesSlice.reducer;
