import React from "react";
import ButtonSpinnerSvg from "../ButtonSpinnerSvg";
import classNames from "classnames";

const Button = ({
  icon: IconComponent,
  title,
  loadingTitle,
  loading,
  size = "md",
  width = "auto",
  variant = "primary",
  ...props
}) => (
  <button
    className={classNames(
      "inline-flex items-center justify-center text-white font-medium rounded-md focus:outline-none disabled:opacity-50 transition ease-in-out duration-300",
      {
        "py-2 px-4 text-sm": size === "sm",
        "py-2.5 px-8 text-sm font-semibold": size === "md",
        "py-2.5 px-16 text-base font-semibold": size === "lg",
        "bg-orange-400 hover:bg-orange-500": variant === "primary",
        "bg-green-500 hover:bg-green-600": variant === "success",
        "bg-red-600 hover:bg-red-700": variant === "danger",
        "bg-gray-800 hover:bg-gray-900": variant === "dark",
        "w-auto": width === "auto",
        "w-full": width === "full",
      }
    )}
    {...props}
  >
    {loading ? (
      <ButtonSpinnerSvg />
    ) : IconComponent ? (
      <IconComponent size={18} className="mr-3 mb-0.5" />
    ) : null}
    {loading ? loadingTitle || title : title}
  </button>
);

export default Button;
