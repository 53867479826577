import React, { useEffect, useState } from "react";
import "./styles.scss";
import PageContent from "../../Dashboard/components/PageContent";
import { CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import produce from "immer";
import { fetchInsurancePolicyByIdRequest } from "state/slices/insurances";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Button from "components/shared/forms/Button";
import * as MaterialIcon from "react-icons/md";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  startPaymentRequest,
  verifyPaymentRequest,
} from "state/slices/payments";
import _ from "lodash";
import { usePaystackPayment } from "react-paystack";

const UserInsuranceDetails = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.account.data);
  const { coverId } = useParams();

  const [state, setState] = useState({
    isFetching: false,
    isStartingPayment: false,
    isVerifyingPayment: false,
    isPaymentSuccessful: false,
    insurancePolicy: {},
    paymentInitializationData: {},
  });

  const amountToPayPlusCharges = parseInt(state.insurancePolicy.premiumAmount) + parseInt(state.insurancePolicy.transactionCharge);

  const config = {
    reference: state.paymentInitializationData.reference,
    email: user.email,
    amount: amountToPayPlusCharges * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
  };

  const initializePaystackPayment = usePaystackPayment(config);

  useEffect(() => {
    fetchInsurancePolicyById().then(() => {
      if (state.insurancePolicy.paymentStatus === 0) {
        return startPayment();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.insurancePolicy.paymentStatus]);

  useEffect(() => {
    if (state.paymentInitializationData.reference) {
      initializePaystackPayment(handlePaymentSuccess, () => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.paymentInitializationData.reference]);

  const fetchInsurancePolicyById = async () => {
    setState(
      produce((draft) => {
        draft.isFetching = true;
      })
    );

    const resultAction = await dispatch(
      fetchInsurancePolicyByIdRequest(coverId)
    );

    if (fetchInsurancePolicyByIdRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);

      setState(
        produce((draft) => {
          draft.isFetching = false;
          draft.insurancePolicy = response.data;
        })
      );
    } else {
      setState(
        produce((draft) => {
          draft.isFetching = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  const startPayment = async () => {
    setState(
      produce((draft) => {
        draft.isStartingPayment = true;
      })
    );

    const resultAction = await dispatch(
      startPaymentRequest(amountToPayPlusCharges)
    );

    if (startPaymentRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);

      setState(
        produce((draft) => {
          draft.isStartingPayment = false;
          draft.paymentInitializationData = _.mapKeys(response.data, (v, k) =>
            _.camelCase(k)
          );
        })
      );
    } else {
      setState(
        produce((draft) => {
          draft.isFetching = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  const verifyPayment = async (reference) => {
    const payload = {
      transactionReference: reference,
      paymentCode: state.insurancePolicy.paymentRef,
    };

    setState(
      produce((draft) => {
        draft.isVerifyingPayment = true;
      })
    );

    const resultAction = await dispatch(verifyPaymentRequest(payload));

    if (verifyPaymentRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);

      await fetchInsurancePolicyById();

      setState(
        produce((draft) => {
          draft.isVerifyingPayment = false;
          draft.isPaymentSuccessful = true;
        })
      );

      toast.success(response.message || "Payment was made successfully.");
    } else {
      setState(
        produce((draft) => {
          draft.isVerifyingPayment = false;
          draft.isPaymentSuccessful = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  const handlePaymentSuccess = ({ reference }) => {
    verifyPayment(reference).then(undefined);
  };

  return (
    <PageContent headerTitle="Insurance Details">
      <CSSTransition
        in={true}
        appear={true}
        timeout={300}
        classNames="animate-translate--y"
        unmountOnExit
      >
        <div className="flex flex-row w-full justify-center pb-8">
          <div className="flex flex-col w-4/5 px-10 pt-6">
            <div className="flex flex-col w-full">
              <h1 className="font-semibold text-xl">
                {state.insurancePolicy.productDescription}
              </h1>
              <div className="flex flex-row items-center space-x-2 mt-1">
                <span className="text-gray-500">
                  {moment(state.insurancePolicy.createdAt).format("lll")}
                </span>
                <span className="h-2 w-2 rounded-full bg-gray-500" />
                <span className="text-gray-500">
                  {state.insurancePolicy.paymentStatus !== "0"
                    ? "Active"
                    : "Inactive"}
                </span>
              </div>
            </div>

            <div className="flex flex-col w-full mt-5 px-8 py-5 bg-gray-100 rounded-xl">
              <h1 className="font-semibold text-xl">Personal Details</h1>
              <div className="grid grid-cols-3 gap-5 mt-5">
                <div className="space-x-2">
                  <span className="w-full bg-orange-400 px-3 py-0.5 font-semibold rounded-sm text-white text-xs">
                    Name
                  </span>
                  <span>{`${state.insurancePolicy.firstname} ${state.insurancePolicy.lastname}`}</span>
                </div>

                <div className="space-x-2">
                  <span className="w-full bg-orange-400 px-3 py-0.5 font-semibold rounded-sm text-white text-xs">
                    DOB
                  </span>
                  <span>{state.insurancePolicy.dob}</span>
                </div>

                <div className="space-x-2">
                  <span className="w-full bg-orange-400 px-3 py-0.5 font-semibold rounded-sm text-white text-xs">
                    Gender
                  </span>
                  <span>{state.insurancePolicy.gender}</span>
                </div>

                <div className="space-x-2">
                  <span className="w-full bg-orange-400 px-3 py-0.5 font-semibold rounded-sm text-white text-xs">
                    Occupation
                  </span>
                  <span>{state.insurancePolicy.occupation}</span>
                </div>

                <div className="col-span-2 space-x-2">
                  <span className="w-full bg-orange-400 px-3 py-0.5 font-semibold rounded-sm text-white text-xs">
                    Address
                  </span>
                  <span>{state.insurancePolicy.address}</span>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full mt-5 px-8 py-5 bg-gray-100 rounded-xl">
              <h1 className="font-semibold text-xl">Insurance Details</h1>
              <div className="flex flex-col items-center space-x-2 mt-5">
                <div className="flex flex-row w-full justify-between py-1.5">
                  <span className="text-gray-600 font-semibold">Number</span>
                  <span className="text-gray-600">
                    #{state.insurancePolicy.id}
                  </span>
                </div>
                <div className="flex flex-row w-full justify-between py-1.5">
                  <span className="text-gray-600 font-semibold">
                    Policy Number
                  </span>
                  <span className="text-gray-600">
                    {state.insurancePolicy.policy_number || "N/A"}
                  </span>
                </div>
                <div className="flex flex-row w-full justify-between py-1.5">
                  <span className="text-gray-600 font-semibold">
                    Insurance Type
                  </span>
                  <span className="text-gray-600">
                    {state.insurancePolicy.businessClassID}
                  </span>
                </div>
                <div className="flex flex-row w-full justify-between py-1.5">
                  <span className="text-gray-600 font-semibold">Risk Type</span>
                  <span className="text-gray-600">
                    {state.insurancePolicy.riskTypeID}
                  </span>
                </div>
                <div className="flex flex-row w-full justify-between py-1.5">
                  <span className="text-gray-600 font-semibold">Premium</span>
                  <span className="text-gray-600">
                    {state.insurancePolicy.premiumAmount}
                  </span>
                </div>
                <div className="flex flex-row w-full justify-between py-1.5">
                  <span className="text-gray-600 font-semibold">Transaction Fees</span>
                  <span className="text-gray-600">
                    {state.insurancePolicy.transactionCharge}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-end lg:w-full px-3 mt-8 space-x-5">
              {state.insurancePolicy.paymentStatus === 0 ? (
                <Button
                  type="button"
                  icon={MaterialIcon.MdPayment}
                  title="Make Payment"
                  loadingTitle={
                    state.isStartingPayment
                      ? "Starting payment"
                      : state.isVerifyingPayment
                      ? "Verifying payment..."
                      : "Making payment..."
                  }
                  variant="success"
                  size="md"
                  width="auto"
                  loading={state.isStartingPayment || state.isVerifyingPayment}
                  disabled={state.isStartingPayment || state.isVerifyingPayment}
                  onClick={startPayment}
                />
              ) : (
                <Button
                  type="button"
                  icon={MaterialIcon.MdRefresh}
                  title="Renew Insurance"
                  variant="dark"
                  size="md"
                  width="auto"
                />
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
    </PageContent>
  );
};

export default UserInsuranceDetails;
