import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import PageContent from "../../Dashboard/components/PageContent";
import { CSSTransition } from "react-transition-group";
import { useDispatch } from "react-redux";
import produce from "immer";
import { fetchAllProductCategoriesRequest } from "state/slices/insurances";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import productCategoryIcons from "config/productCategoryIcons";
import Button from "components/shared/forms/Button";
import Modal from "../../../shared/Modal";
const InsuranceList = () => {
  
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(showModal=>!showModal);
  };

  const dispatch = useDispatch();

  const [state, setState] = useState({
    isFetching: false,
    productCategories: [],
  });

  const [productPackage, setProductPackage] = useState([
    {
      id: 1,
      title: "Group Personal Accident",
      iconDetails: "MOT",
      Icon : productCategoryIcons.SAFETYPLUS,
    },
    {
      id: 2,
      title: "Fire Insurance",
      iconDetails: "MOT",
      Icon : productCategoryIcons.FIRE,
    },
    {
      id: 3,
      title: "Motor Comprehensive",
      iconDetails: "MOT",
      Icon : productCategoryIcons.MOT,
    },
    {
      id: 4,
      title: "Material Damage",
      iconDetails: "MOT",
      Icon : productCategoryIcons.MATERIALDAMAGE,
    },
    {
      id: 5,
      title: "Third Party Liability",
      iconDetails: "MOT",
      Icon : productCategoryIcons.LIABILITY,
    },
    {
      id: 6,
      title: "All Risk",
      iconDetails: "MOT",
      Icon : productCategoryIcons.ALLRISKS,
    },
    {
      id: 7,
      title: "Educational Activities & Trips",
      iconDetails: "MOT",
      Icon : productCategoryIcons.EDUCATIONALTRIPS,
    },
  ]);

  useEffect(() => {
    fetchAllProductCategories().then(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllProductCategories = async () => {
    setState(
      produce((draft) => {
        draft.isFetching = true;
      })
    );

    const resultAction = await dispatch(fetchAllProductCategoriesRequest());

    if (fetchAllProductCategoriesRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);

      setState(
        produce((draft) => {
          draft.isFetching = false;
          draft.productCategories = response.data;
        })
      );
    } else {
      setState(
        produce((draft) => {
          draft.isFetching = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <PageContent headerTitle="SSLAG" bg="gray-100">
      {state.isFetching ? (
        <div className="flex flex-row w-full pb-8">
          <div className="flex flex-col w-full">
            <div className="grid grid-cols-2 xl:grid-cols-3 gap-6 items-center px-10 pt-12 mx-0 lg:mx-28">
              {[...Array(6)].map((el, index) => (
                <div
                  key={index}
                  className="flex flex-col justify-center items-center rounded-3xl bg-white py-14 md:py-20"
                >
                  <div className="bg-gray-200 h-16 w-16 rounded animate-pulse" />
                  <div className="bg-gray-200 h-4 mt-2 rounded w-5/6 animate-pulse" />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <CSSTransition
          in={true}
          appear={true}
          timeout={300}
          classNames="animate-translate--y"
          unmountOnExit
        >
            <div className="flex flex-row w-full pb-8">
              
              <div className="flex flex-col w-full pt-8 mx-0 lg:mx-10">
                <div className="px-10 pt-0 mx-0 lg:mx-10">
                  The SSLAG compulsory Insurance for schools in Lagos State
                  is a policy that provides cover for students in the event
                  of death, permanent disability or medical expenses as a result
                  of an accident while in school or school-related activities.
                </div>
                <div className="grid grid-cols-2 xl:grid-cols-4 gap-3 items-center px-10 pt-12 mx-0 lg:mx-10">
                  {productPackage.map(
                  ({
                    id,
                    title,
                    iconDetails,
                    Icon = productCategoryIcons[iconDetails]
                  }) => (
                    <div
                      className="flex flex-col justify-center items-center rounded-3xl bg-white py-12 md:py-12"
                    >
                      <Icon size={60} className="text-red-600" />
                      <span className="text-gray-500 text-md text-center font-semibold mt-2">
                        {_.startCase(title)}
                      </span>
                    </div>
                  )
                  )}
                  
                
                </div>
                <br />
                <div  className="grid grid-cols-2 xl:grid-cols-2 gap-3 items-center px-10 pt-12 mx-0 lg:mx-10">
                 
                      <Button
                        type="button"
                        title={"More Info"}
                        variant="success"
                        size="lg"
                        width="full"
                        onClick={openModal}
                  />
                  {showModal && <Modal setIsOpen={setShowModal} />}
                  <Link
                        className="w-full transition ease-in-out duration-300"
                        to="/dashboard/sslag/kyc-form"
                    >
                      <Button
                          type="button"
                          title={"Proceed"}
                          variant="primary"
                          size="lg"
                          width="full"
                      />
                  </Link>
                </div>
              </div>
              
          </div>
        </CSSTransition>
      )}
    </PageContent>
  );
};

export default InsuranceList;
