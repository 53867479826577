import React, { Fragment, useContext, useState } from "react";
import { Field, useFormikContext } from "formik";
import Button from "components/shared/forms/Button";
import WhiteBackgroundTextInput from "components/shared/forms/WhiteBackgroundTextInput";
import { CreateInsuranceContext } from "contexts/CreateInsuranceContext";
import Select from "components/shared/forms/Select";
import AsyncSelect from "components/shared/forms/AsyncSelect";
import { convertArrayToSelectOptions } from "utils";
import { fetchProductByBusinessClassIdRequest } from "state/slices/insurances";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "components/shared/forms/DatePicker";
import GetQuoteNoticeModal from "../../GetQuoteNoticeModal";

const titleOptions = convertArrayToSelectOptions([
  "Dr. (Doctor)",
  "Esq. (Esquire)",
  "Hon. (Honorable)",
  "Jr. (Junior)",
  "Mr.",
  "Mrs.",
  "Ms.",
  "HRH",
  "Engr.",
  "Major",
  "Messrs. (plural of Mr.)",
  "Mmes. (plural of Mrs.)",
  "Msgr. (Monsignor)",
  "Prof. (Professor)",
  "Rev. (Reverend)",
  "Rt. Hon. (Right Honorable)",
  "Sr. (Senior)",
  "St. (Saint)",
]);

const genderOptions = convertArrayToSelectOptions(["Male", "Female"]);

const clientTypeOptions = [
  { value: "1", label: "Individual" },
  { value: "2", label: "Corporate" },
];

const meansOfIdentificationOptions = convertArrayToSelectOptions([
  "International Passport",
  "Driver’s License",
  "National ID Card",
  "Voter's Card",
]);

const CustomerDetailsFormSection = () => {
  const dispatch = useDispatch();
  const formikContext = useFormikContext();
  const {
    currentStep,
    lastStep,
    nextStep,
    previousStep,
    businessClassId,
  } = useContext(CreateInsuranceContext);

  const [showQuoteOnlyNotice, setShowQuoteOnlyNotice] = useState(false);

  const insuranceCompaniesOptions = useSelector(
    (state) => state.insurances.insuranceCompanies || {}
  ).map(({ company, name }) => ({ label: name, value: company }));

  const loadRiskTypeOptions = async () => {
    const resultAction = await dispatch(
      fetchProductByBusinessClassIdRequest({ businessClassId })
    );

    const response = unwrapResult(resultAction);

    const options = (response.data || []).map((product) => ({
      ...product,
      value: product.RiskTypeID,
      label: product.RiskName,
    }));

    if (options.length === 0) {
      setShowQuoteOnlyNotice(true);
    }

    return { options };
  };

  return (
    <Fragment>
      <GetQuoteNoticeModal
        isOpen={showQuoteOnlyNotice}
        onRequestClose={() => setShowQuoteOnlyNotice(false)}
      />

      <div className="-mx-3 lg:flex mt-0 lg:mt-0">
        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="riskType"
              className="text-sm text-gray-600 font-semibold"
            >
              Risk Type
            </label>
            <Field
              component={AsyncSelect}
              name="riskTypeID"
              id="riskType"
              placeholder="Please select a risk type"
              loadOptions={loadRiskTypeOptions}
            />
          </div>
        </div>
        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="riskType"
              className="text-sm text-gray-600 font-semibold"
            >
              Insurance Company
            </label>
            <Field
              component={Select}
              name="insuranceCompany"
              id="insuranceCompany"
              placeholder="Please select insurance company"
              options={insuranceCompaniesOptions}
            />
          </div>
        </div>
        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="title"
              className="text-sm text-gray-600 font-semibold"
            >
              Title
            </label>
            <Field
              component={Select}
              type="text"
              name="maritalStatus"
              id="title"
              placeholder="Please select title"
              options={titleOptions}
            />
          </div>
        </div>
      </div>
      <div className="-mx-3 lg:flex mt-0 lg:mt-6">
        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="firstName"
              className="text-sm text-gray-600 font-semibold"
            >
              First Name
            </label>
            <Field
              component={WhiteBackgroundTextInput}
              type="text"
              name="firstname"
              id="firstName"
              placeholder="First Name"
            />
          </div>
        </div>
        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="lastName"
              className="text-sm text-gray-600 font-semibold"
            >
              Last Name
            </label>
            <Field
              component={WhiteBackgroundTextInput}
              type="text"
              name="lastname"
              id="lastName"
              placeholder="Last Name"
            />
          </div>
        </div>
        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="emailAddress"
              className="text-sm text-gray-600 font-semibold"
            >
              Other Name
            </label>
            <Field
              component={WhiteBackgroundTextInput}
              type="text"
              name="othername"
              id="otherName"
              placeholder="Other Name"
            />
          </div>
        </div>
      </div>
      <div className="-mx-3 lg:flex mt-0 lg:mt-6">
        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="gender"
              className="text-sm text-gray-600 font-semibold"
            >
              Gender
            </label>
            <Field
              component={Select}
              name="gender"
              id="gender"
              placeholder="Please select a gender"
              options={genderOptions}
            />
          </div>
        </div>
        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="phoneNumber"
              className="text-sm text-gray-600 font-semibold"
            >
              Phone Number
            </label>
            <Field
              component={WhiteBackgroundTextInput}
              type="text"
              name="phone"
              id="phoneNumber"
              placeholder="Phone Number"
            />
          </div>
        </div>
        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="emailAddress"
              className="text-sm text-gray-600 font-semibold"
            >
              Email Address
            </label>
            <Field
              component={WhiteBackgroundTextInput}
              type="email"
              name="email"
              id="emailAddress"
              placeholder="Email Address"
            />
          </div>
        </div>
      </div>
      <div className="-mx-3 lg:flex mt-0 lg:mt-6">
        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="dateOfBirth"
              className="text-sm text-gray-600 font-semibold"
            >
              Date of Birth
            </label>
            <Field
              component={DatePicker}
              name="dob"
              id="dateOfBirth"
              variant="white"
              placeholder="Date of Birth"
            />
          </div>
        </div>
        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="phoneNumber"
              className="text-sm text-gray-600 font-semibold"
            >
              Client Type
            </label>
            <Field
              component={Select}
              name="clientType"
              id="clientType"
              placeholder="Please select a client type"
              options={clientTypeOptions}
            />
          </div>
        </div>
        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="meansOfId"
              className="text-sm text-gray-600 font-semibold"
            >
              Means of Identification
            </label>
            <Field
              component={Select}
              name="meansOfId"
              id="meansOfId"
              placeholder="Please select a means of identification"
              options={meansOfIdentificationOptions}
            />
          </div>
        </div>
      </div>
      <div className="-mx-3 lg:flex mt-0 lg:mt-6">
        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="meansOfId"
              className="text-sm text-gray-600 font-semibold"
            >
              Means of Identification Number
            </label>
            <Field
              component={WhiteBackgroundTextInput}
              type="text"
              name="meansOfIdNumber"
              id="meansOfIdNumber"
              placeholder="Means of Identification Number"
            />
          </div>
        </div>
        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="occupation"
              className="text-sm text-gray-600 font-semibold"
            >
              Occupation
            </label>
            <Field
              component={WhiteBackgroundTextInput}
              type="text"
              name="occupation"
              id="occupation"
              placeholder="Occupation"
            />
          </div>
        </div>
        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="address"
              className="text-sm text-gray-600 font-semibold"
            >
              Address
            </label>
            <Field
              component={WhiteBackgroundTextInput}
              type="text"
              name="address"
              id="address"
              placeholder="Address"
            />
          </div>
        </div>
      </div>
      <div className="-mx-3 lg:flex mt-0 lg:mt-6">
        <div className="lg:w-full px-3 mt-6 lg:mt-0">
          <div className="flex flex-col">
            <label
              htmlFor="policyStartDate"
              className="text-sm text-gray-600 font-semibold"
            >
              Policy Start Date
            </label>
            <Field
              component={DatePicker}
              variant="white"
              type="text"
              name="policyStartDate"
              id="policyStartDate"
              placeholder="Policy Start Date"
            />
          </div>
        </div>
      </div>
      <div className="-mx-3 lg:flex mt-0 lg:mt-8">
        <div className="flex flex-row justify-end lg:w-full px-3 mt-8 lg:mt-0 space-x-5">
          <Button
            type="button"
            title="Previous"
            variant="dark"
            size="md"
            width="auto"
            disabled={currentStep === 0}
            onClick={previousStep}
          />
          <Button
            type="button"
            title={currentStep === lastStep ? "Submit" : "Next"}
            variant="primary"
            size="md"
            width="auto"
            disabled={!formikContext.isValid}
            loading={formikContext.isSubmitting}
            onClick={() => nextStep(formikContext)}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerDetailsFormSection;
