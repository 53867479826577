import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import PageContent from "../../Dashboard/components/PageContent";
import { CSSTransition } from "react-transition-group";
import { useDispatch } from "react-redux";
import produce from "immer";
import { fetchAllProductCategoriesRequest } from "state/slices/insurances";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import productCategoryIcons from "config/productCategoryIcons";

const QuotableInsuranceList = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    isFetching: false,
    productCategories: [],
  });

  useEffect(() => {
    fetchAllProductCategories().then(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllProductCategories = async () => {
    setState(
      produce((draft) => {
        draft.isFetching = true;
      })
    );

    const resultAction = await dispatch(fetchAllProductCategoriesRequest());

    if (fetchAllProductCategoriesRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);

      setState(
        produce((draft) => {
          draft.isFetching = false;
          draft.productCategories = response.data;
        })
      );
    } else {
      setState(
        produce((draft) => {
          draft.isFetching = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <PageContent headerTitle="Get Insurance Quote" bg="gray-100">
      {state.isFetching ? (
        <div className="flex flex-row w-full pb-8">
          <div className="flex flex-col w-full">
            <div className="grid grid-cols-2 xl:grid-cols-3 gap-6 items-center px-10 pt-12 mx-0 lg:mx-28">
              {[...Array(6)].map((el, index) => (
                <div
                  key={index}
                  className="flex flex-col justify-center items-center rounded-3xl bg-white py-14 md:py-20"
                >
                  <div className="bg-gray-200 h-16 w-16 rounded animate-pulse" />
                  <div className="bg-gray-200 h-4 mt-2 rounded w-5/6 animate-pulse" />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <CSSTransition
          in={true}
          appear={true}
          timeout={300}
          classNames="animate-translate--y"
          unmountOnExit
        >
          <div className="flex flex-row w-full pb-8">
            <div className="flex flex-col w-full">
              <div className="grid grid-cols-2 xl:grid-cols-3 gap-6 items-center px-10 pt-12 mx-0 lg:mx-28">
                {state.productCategories.map(
                  ({
                    BusinessClassID: id,
                    BusinessClassName: title,
                    Icon = productCategoryIcons[id] ||
                      productCategoryIcons.OTHERS,
                    to = `/dashboard/get-quote/type/${id.toLowerCase()}`,
                  }) => (
                    <Link
                      key={id}
                      className="flex flex-col justify-center items-center rounded-3xl bg-white py-14 md:py-20"
                      to={to}
                    >
                      <Icon size={60} className="text-orange-400" />
                      <span className="text-gray-500 text-md text-center font-semibold mt-2">
                        {_.startCase(title.toLowerCase())}
                      </span>
                    </Link>
                  )
                )}
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
    </PageContent>
  );
};

export default QuotableInsuranceList;
