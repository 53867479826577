import React, { useEffect, useState } from "react";
import "./styles.scss";
import PageContent from "components/pages/Dashboard/components/PageContent";
import { Formik } from "formik";
import * as yup from "yup";
import { CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import produce from "immer";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createInsurancePolicyRequest,
  fetchProductDocumentsByRiskTypeIdRequest,
} from "state/slices/insurances";
import BuyInsuranceForm from "./components/BuyInsuranceForm";
import { CreateInsuranceContext } from "contexts/CreateInsuranceContext";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { getValidationSchema } from "utils";
import FormikEffect from "components/shared/FormikEffect";
import { uploadFileRequest } from "state/slices/files";
import moment from "moment";

const BuyInsurance = ({ history }) => {
  const dispatch = useDispatch();
  const { businessClassId } = useParams();
  const { user } = useSelector((state) => state.account.data);

  const [state, setState] = useState({
    currentStep: 0,
    lastStep: 2,
    isFetchingProduct: false,
    insuranceProducts: [],
    isFetchingDocuments: false,
    insuranceDocuments: [],
    riskType: {
      RiskTypeID: "",
      RiskName: "",
      Tarrif: "",
    },
    isSubmittingForm: false,
  });

  useEffect(() => {
    if (state.riskType.RiskTypeID) {
      (() => fetchProductDocumentsByRiskTypeId())();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.riskType.RiskTypeID]);

  const updateRiskType = (riskType) => {
    setState(
      produce((draft) => {
        draft.riskType = riskType;
      })
    );
  };

  const previousStep = () => {
    setState(
      produce((draft) => {
        draft.currentStep = Math.max(state.currentStep - 1, 0);
      })
    );
  };

  const nextStep = async ({
    submitForm,
    validateForm,
    isValid,
    setTouched,
  }) => {
    await submitForm();
    await validateForm();

    if (!isValid) {
      return false;
    }

    setState(
      produce((draft) => {
        draft.currentStep = Math.min(state.currentStep + 1, state.lastStep);
      })
    );

    setTouched({});
  };

  const fetchProductDocumentsByRiskTypeId = async () => {
    setState(
      produce((draft) => {
        draft.isFetchingDocuments = true;
      })
    );

    const resultAction = await dispatch(
      fetchProductDocumentsByRiskTypeIdRequest({
        riskTypeId: state.riskType.RiskTypeID,
      })
    );

    if (
      fetchProductDocumentsByRiskTypeIdRequest.fulfilled.match(resultAction)
    ) {
      const response = unwrapResult(resultAction);

      setState(
        produce((draft) => {
          draft.isFetchingDocuments = false;
          draft.insuranceDocuments = response.docs;
        })
      );
    } else {
      setState(
        produce((draft) => {
          draft.isFetchingDocuments = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  const insuranceDocumentTexts = state.insuranceDocuments.filter(
    (item) => item.type === "text"
  );

  const insuranceDocumentFiles = state.insuranceDocuments.filter(
    (item) => item.type === "file"
  );

  const initialValues = {
    insuranceCompany: "",
    maritalStatus: "",
    firstname: "",
    lastname: "",
    othername: "",
    phone: "",
    email: "",
    dob: "",
    clientType: "",
    meansOfId: "",
    meansOfIdNumber: "",
    occupation: "",
    address: "",
    policyStartDate: "",
    sumInsured: "",
    productType: "",
    companyID: "",
    businessClassID: "",
    riskTypeID: "",
    productDescription: "",
    premiumAmount: "",
    vehicle_value: "",
    vehicle_chasisNumber: "",
    vehicle_engineNumber: "",
    vehicle_regNumber: "",
    vehicle_type: "",
    vehicle_make: "",
    vehicle_color: "",
    vehicle_license: "",
    vehicle_proofOfOwnership: "",
    vehicle_image: "",
    marine_proformaInvoice: "",
    marine_InvoiceValue: "",
    marine_coverType: "",
    marine_sailingFrom: "",
    marine_sailingTo: "",
    marine_proformaInvoiceDate: "",
    marine_modeOfShippment: "",
    marine_packageType: "",
    marine_vesselName: "",
    marine_cargoDescription: "",
    marine_extentCover: "",
    marine_tin: "",
    git_natureOfGood: "",
    git_modeOfTransit: "",
    git_transitFrom: "",
    git_transitTo: "",
    git_transitDuration: "",
    git_modeOfTransitOwnsership: "",
    git_valueOfGoodsToBeCarried: "",
    pa_sumInsuredDeathBenefit: "",
    pa_sumInsuredPermanentDisability: "",
    pa_limitOfReimbursement: "",
    gpa_groupPersonalAccidentDetailsURL: "",
    pi_businessAddress: "",
    pi_limitOfLiability: "",
    pi_aggregateLimit: "",
    life_certOfIncorpration: "",
    life_staffDataExcelSheetURL: "",
    groupLife_staffDataExcelSheetURL: "",
    fsp_descriptionOfBuilding: "",
    fsp_locationOfBuilding: "",
    fsp_valueOfBuilding: "",
    fsp_imageOfBuilding: "",
    fsp_inventoryWithContentDetailsURL: "",
    otherInformation: "",
    otherInformationFile: "",
    user: "",
  };

  const customerDetailsValidationSchema = yup.object().shape({
    maritalStatus: yup.string().label("Title").required(),
    firstname: yup.string().label("First name").required(),
    lastname: yup.string().label("Last name").required(),
    othername: yup.string().label("Other name").required(),
    gender: yup.string().label("Gender").required(),
    phone: yup.string().label("Phone number").required(),
    email: yup.string().label("Email address").required(),
    dob: yup.string().label("Date of birth").required(),
    clientType: yup.string().label("Client type").required(),
    meansOfId: yup.string().label("Means of identity").required(),
    meansOfIdNumber: yup.string().label("Means of identity Number").required(),
    occupation: yup.string().label("Occupation").required(),
    address: yup.string().label("Address").required(),
    policyStartDate: yup.string().label("Policy start date").required(),
    riskTypeID: yup.string().label("Risk type").required(),
    insuranceCompany: yup.string().label("Insurance company").required(),
  });

  const insuranceDetailsSchema = getValidationSchema(insuranceDocumentTexts);
  const uploadDocumentsSchema = getValidationSchema(insuranceDocumentFiles);

  const schemaArray = [
    customerDetailsValidationSchema,
    insuranceDetailsSchema,
    uploadDocumentsSchema,
  ];

  const uploadFile = async (formPayload) => {
    const files = [];

    for (const document of insuranceDocumentFiles) {
      const formData = new FormData();
      formData.append("file", formPayload[document.field]);

      const resultAction = await dispatch(uploadFileRequest(formData));

      if (uploadFileRequest.fulfilled.match(resultAction)) {
        const response = unwrapResult(resultAction);
        files.push({ field: document.field, url: response.url });
      }
    }

    return files;
  };

  const handleBuyInsurance = async (formValues) => {
    if (state.currentStep !== state.lastStep) {
      return false;
    }

    const fileNames = await uploadFile(formValues);

    const fileNameValues = _.mapValues(
      _.keyBy(fileNames, "field"),
      (item) => item.url
    );

    let formattedFormValues = _.mapValues(formValues, (item) => {
      if (typeof item === "object" && item.label && item.value) {
        return item.value;
      } else if (item instanceof Date) {
        return moment(item).format("YYYY/MM/DD");
      } else {
        return item;
      }
    });

    const formPayload = {
      ..._.omitBy(formattedFormValues, _.isEmpty),
      businessClassID: businessClassId,
      maritalStatus: formattedFormValues.maritalStatus,
      gender: formattedFormValues.gender,
      clientType: formattedFormValues.clientType,
      meansOfId: formattedFormValues.meansOfId,
      riskTypeID: formattedFormValues.riskTypeID,
      insuranceCompany: formattedFormValues.insuranceCompany,
      user: user.userCode,
      premiumAmount: state.riskType.Tarrif.toString(),
      productDescription: state.riskType.RiskName,
      ...fileNameValues,
    };

    setState(
      produce((draft) => {
        draft.isSubmittingForm = true;
      })
    );

    const resultAction = await dispatch(
      createInsurancePolicyRequest(formPayload)
    );

    if (createInsurancePolicyRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);

      setState(
        produce((draft) => {
          draft.isSubmittingForm = false;
        })
      );

      history.push(`/dashboard/insurances/${response.data.coverid}/details`);

      toast.success(
        response.message || "Insurance policy created successfully."
      );
    } else {
      setState(
        produce((draft) => {
          draft.isSubmittingForm = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <PageContent headerTitle="Buy Insurance">
      <CSSTransition
        in={true}
        appear={true}
        timeout={300}
        classNames="animate-translate--y"
        unmountOnExit
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={schemaArray[state.currentStep]}
          onSubmit={handleBuyInsurance}
        >
          {(formikProps) => (
            <CreateInsuranceContext.Provider
              value={{
                insuranceDocuments: state.insuranceDocuments,
                businessClassId,
                currentStep: state.currentStep,
                lastStep: state.lastStep,
                nextStep: nextStep,
                previousStep: previousStep,
              }}
            >
              <FormikEffect
                field="riskTypeID"
                callback={() => updateRiskType(formikProps.values.riskTypeID)}
              />
              <BuyInsuranceForm />
            </CreateInsuranceContext.Provider>
          )}
        </Formik>
      </CSSTransition>
    </PageContent>
  );
};

export default BuyInsurance;
