import React, { useState } from "react";
import "./styles.scss";
import PageContent from "components/pages/Dashboard/components/PageContent";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import TextInput from "components/shared/forms/TextInput";
import Button from "components/shared/forms/Button";
import { CSSTransition } from "react-transition-group";
import _ from "lodash";
import produce from "immer";
import { createInsuranceClaimRequest } from "state/slices/insurances";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { uploadFileRequest } from "state/slices/files";
import DatePicker from "components/shared/forms/DatePicker";
import FileInput from "components/shared/forms/FileInput";

const CustomTextInput = (props) => <TextInput variant="white" {...props} />;
const CustomFileInput = (props) => <FileInput variant="white" {...props} />;

const ReportClaim = ({ history }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.account.data);

  const [state, setState] = useState({
    isSubmittingForm: false,
  });

  const initialValues = {
    coverType: "",
    dateOfReport: "",
    timeOfIncidence: "",
    locationOfIncidence: "",
    policeReport: "",
    document1: "",
    document2: "",
    document3: "",
    incidenceDescription: "",
    otherDetails: "",
    fullname: "",
    phone: "",
    email: "",
    user: "",
  };

  const validationSchema = yup.object().shape({
    coverType: yup.string().label("Policy number").required(),
    dateOfReport: yup.string().label("Date of Report").required(),
    timeOfIncidence: yup.string().label("Time of Incidence").required(),
    locationOfIncidence: yup.string().label("Location of Incidence").required(),
    document1: yup.string().label("Document 1").required(),
    document2: yup.string().label("Document 2").required(),
    document3: yup.string().label("Document 3"),
    incidenceDescription: yup.string().label("Description").required(),
    otherDetails: yup.string().label("Other details").required(),
  });

  const uploadFile = async (formPayload) => {
    const files = [];
    const documents = ["document1", "document2", "document3"];

    for (const document of documents) {
      if (!formPayload[document]) {
        continue;
      }

      const formData = new FormData();
      formData.append("file", formPayload[document]);

      const resultAction = await dispatch(uploadFileRequest(formData));

      if (uploadFileRequest.fulfilled.match(resultAction)) {
        const response = unwrapResult(resultAction);
        files.push({ field: document, url: response.url });
      }
    }

    return files;
  };

  const handleReportClaim = async (formValues) => {
    const fileNames = await uploadFile(formValues);

    const fileNameValues = _.mapValues(
      _.keyBy(fileNames, "field"),
      (item) => item.url
    );

    const formPayload = {
      fullname: `${user.firstname} ${user.lastname}`,
      phone: user.phone,
      email: user.email,
      user: user.userCode,
      coverType: formValues.coverType,
      incidenceDescription: formValues.incidenceDescription,
      location: formValues.locationOfIncidence,
      reportDate: formValues.dateOfReport,
      otherDetails: formValues.otherDetails,
      ...fileNameValues,
    };

    setState(
      produce((draft) => {
        draft.isSubmittingForm = true;
      })
    );

    const resultAction = await dispatch(
      createInsuranceClaimRequest(formPayload)
    );

    if (createInsuranceClaimRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);

      setState(
        produce((draft) => {
          draft.isSubmittingForm = false;
        })
      );

      history.push(`/dashboard/claims`);

      toast.success(
        response.message || "Insurance claim created successfully."
      );
    } else {
      setState(
        produce((draft) => {
          draft.isSubmittingForm = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <PageContent headerTitle="Report Claim">
      <CSSTransition
        in={true}
        appear={true}
        timeout={300}
        classNames="animate-translate--y"
        unmountOnExit
      >
        <div className="flex flex-row w-full pb-8">
          <div className="flex flex-col w-full">
            <div className="flex flex-col w-full px-10 pt-12">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleReportClaim}
              >
                {({ handleSubmit, isValid, setFieldValue }) => {
                  return (
                    <Form
                      className="flex flex-col w-full"
                      onSubmit={handleSubmit}
                    >
                      <div className="-mx-3 lg:flex mt-0 lg:mt-0">
                        <div className="lg:w-1/2 px-3 mt-6 lg:mt-0">
                          <div className="flex flex-col">
                            <label
                              htmlFor="coverType"
                              className="text-sm text-gray-600 font-semibold"
                            >
                              Cover Type
                            </label>
                            <Field
                              component={CustomTextInput}
                              type="text"
                              name="coverType"
                              id="coverType"
                              placeholder="Cover Type"
                            />
                          </div>
                        </div>
                        <div className="lg:w-1/2 px-3 mt-6 lg:mt-0">
                          <div className="flex flex-col">
                            <label
                              htmlFor="dateOfReport"
                              className="text-sm text-gray-600 font-semibold"
                            >
                              Date of Report
                            </label>
                            <Field
                              component={DatePicker}
                              type="text"
                              name="dateOfReport"
                              id="dateOfReport"
                              placeholder="Date of Report"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="-mx-3 lg:flex mt-0 lg:mt-6">
                        <div className="lg:w-1/2 px-3 mt-6 lg:mt-0">
                          <div className="flex flex-col">
                            <label
                              htmlFor="timeOfIncidence"
                              className="text-sm text-gray-600 font-semibold"
                            >
                              Time of Incidence
                            </label>
                            <Field
                              component={CustomTextInput}
                              type="text"
                              name="timeOfIncidence"
                              id="timeOfIncidence"
                              placeholder="Time of Incidence"
                            />
                          </div>
                        </div>
                        <div className="lg:w-1/2 px-3 mt-6 lg:mt-0">
                          <div className="flex flex-col">
                            <label
                              htmlFor="locationOfIncidence"
                              className="text-sm text-gray-600 font-semibold"
                            >
                              Location of Incidence
                            </label>
                            <Field
                              component={CustomTextInput}
                              type="text"
                              name="locationOfIncidence"
                              id="locationOfIncidence"
                              placeholder="Location of Incidence"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="-mx-3 lg:flex mt-0 lg:mt-6">
                        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
                          <div className="flex flex-col">
                            <label
                              htmlFor="policeReport"
                              className="text-sm text-gray-600 font-semibold"
                            >
                              Document 1
                            </label>
                            <Field
                              component={CustomFileInput}
                              type="file"
                              name="document1"
                              id="document1"
                              onChange={(event) => {
                                setFieldValue(
                                  "document1",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
                          <div className="flex flex-col">
                            <label
                              htmlFor="policeReport"
                              className="text-sm text-gray-600 font-semibold"
                            >
                              Document 2
                            </label>
                            <Field
                              component={CustomFileInput}
                              type="file"
                              name="document2"
                              id="document2"
                              onChange={(event) => {
                                setFieldValue(
                                  "document2",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="lg:w-1/3 px-3 mt-6 lg:mt-0">
                          <div className="flex flex-col">
                            <label
                              htmlFor="policeReport"
                              className="text-sm text-gray-600 font-semibold"
                            >
                              Document 3
                            </label>
                            <Field
                              component={CustomFileInput}
                              type="file"
                              name="document3"
                              id="document3"
                              onChange={(event) => {
                                setFieldValue(
                                  "document3",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="-mx-3 lg:flex mt-0 lg:mt-6">
                        <div className="lg:w-full px-3 mt-6 lg:mt-0">
                          <div className="flex flex-col">
                            <label
                              htmlFor="policeReport"
                              className="text-sm text-gray-600 font-semibold"
                            >
                              Incidence Description
                            </label>
                            <Field
                              component={CustomTextInput}
                              type="text"
                              name="incidenceDescription"
                              id="incidenceDescription"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="-mx-3 lg:flex mt-0 lg:mt-6">
                        <div className="lg:w-full px-3 mt-6 lg:mt-0">
                          <div className="flex flex-col">
                            <label
                              htmlFor="policeReport"
                              className="text-sm text-gray-600 font-semibold"
                            >
                              Other Details
                            </label>
                            <Field
                              component={CustomTextInput}
                              type="text"
                              name="otherDetails"
                              id="otherDetails"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="-mx-3 lg:flex mt-0 lg:mt-8">
                        <div className="flex flex-row justify-end lg:w-full px-3 mt-8 lg:mt-0">
                          <Button
                            type="submit"
                            title="Submit"
                            loadingTitle="Submitting..."
                            variant="success"
                            width="auto"
                            loading={state.isSubmittingForm}
                            disabled={!isValid || state.isSubmittingForm}
                          />
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </CSSTransition>
    </PageContent>
  );
};

export default ReportClaim;
