import React from "react";

const ContentSkeleton = () => (
  <div className="flex flex-col w-full">
    <div className="flex flex-row flex-wrap justify-between items-center px-10 pt-6">
      <div className="flex flex-row w-full lg:w-auto space-x-5">
        <div className="inline-flex items-center justify-center w-36 h-10 py-2 px-4 bg-gray-200 rounded-md animate-pulse" />
      </div>
      <div className="flex flex-row flex-wrap w-full lg:w-auto mt-5 lg:mt-0 space-x-5">
        <div className="flex flex-row w-28 h-9 items-center px-5 py-2 bg-gray-200 rounded-md animate-pulse" />
        <div className="flex flex-row w-56 h-9 items-center px-5 py-2 bg-gray-200 rounded-md animate-pulse" />
      </div>
    </div>

    <div className="flex flex-col w-full px-10 pt-6">
      <div className="flex-col w-full bg-white space-y-2">
        <div className="flex flex-row w-full h-12 items-center px-5 py-2 bg-gray-200 rounded-sm border-b-2 border-gray-300 animate-pulse" />
        <div className="flex flex-row w-full h-12 items-center px-5 py-2 bg-gray-200 rounded-sm animate-pulse" />
        <div className="flex flex-row w-full h-12 items-center px-5 py-2 bg-gray-200 rounded-sm animate-pulse" />
        <div className="flex flex-row w-full h-12 items-center px-5 py-2 bg-gray-200 rounded-sm animate-pulse" />
        <div className="flex flex-row w-full h-12 items-center px-5 py-2 bg-gray-200 rounded-sm animate-pulse" />
        <div className="flex flex-row w-full h-12 items-center px-5 py-2 bg-gray-200 rounded-sm animate-pulse" />
        <div className="flex flex-row w-full h-12 items-center px-5 py-2 bg-gray-200 rounded-sm animate-pulse" />
      </div>

      <div className="inline-flex items-center justify-end space-x-2 pt-8">
        <div className="inline-flex items-center w-6 h-6 justify-center bg-gray-200 text-white font-medium p-1 rounded-md hover:bg-gray-700 animate-pulse" />
        <div className="inline-flex items-center w-10 h-10 justify-center bg-gray-200 text-white font-medium p-1 rounded-md hover:bg-gray-700 animate-pulse" />
        <div className="inline-flex items-center w-6 h-6 justify-center bg-gray-200 text-white font-medium p-1 rounded-md hover:bg-gray-700 animate-pulse" />
        <div className="w-16 h-4 bg-gray-200 animate-pulse" />
      </div>
    </div>
  </div>
);

export default ContentSkeleton;
