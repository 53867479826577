import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Insurance } from "services/network";

const initialState = {
  insuranceCompanies: [],
  insuranceCategories: [],
  insuranceProducts: [],
  insurancePolicies: [],
};

export const fetchAllInsuranceCompaniesRequest = createAsyncThunk(
  "insurances/fetchAllInsuranceCompanies",
  async (payload, thunkAPI) => {
    try {
      const response = await Insurance.fetchAllInsuranceCompanies();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchAllProductCategoriesRequest = createAsyncThunk(
  "insurances/fetchAllProductCategories",
  async (payload, thunkAPI) => {
    try {
      const response = await Insurance.fetchAllProductCategories();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchAllProductsRequest = createAsyncThunk(
  "insurances/fetchAllProducts",
  async (payload, thunkAPI) => {
    try {
      const response = await Insurance.fetchAllProducts();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchProductByBusinessClassIdRequest = createAsyncThunk(
  "insurances/fetchProductByBusinessClassId",
  async (payload, thunkAPI) => {
    try {
      const response = await Insurance.fetchProductByBusinessClassId(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchQuotableProductByBusinessClassIdRequest = createAsyncThunk(
  "insurances/fetchQuotableProductByBusinessClassId",
  async (payload, thunkAPI) => {
    try {
      const response = await Insurance.fetchQuotableProductByBusinessClassId(
        payload
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchProductDocumentsByRiskTypeIdRequest = createAsyncThunk(
  "insurances/fetchProductDocumentsByRiskTypeId",
  async (payload, thunkAPI) => {
    try {
      const response = await Insurance.fetchProductDocumentsByRiskTypeId(
        payload
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createInsurancePolicyRequest = createAsyncThunk(
  "insurances/createInsurancePolicy",
  async (payload, thunkAPI) => {
    try {
      const response = await Insurance.createInsurancePolicy(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createInsuranceQuoteRequest = createAsyncThunk(
  "insurances/createInsuranceQuote",
  async (payload, thunkAPI) => {
    try {
      const response = await Insurance.createInsuranceQuote(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchAllInsurancePoliciesByUserIdRequest = createAsyncThunk(
  "insurances/fetchAllInsurancePoliciesByUserId",
  async (payload, thunkAPI) => {
    try {
      const response = await Insurance.fetchAllInsurancePoliciesByUserId(
        thunkAPI.getState().account.data.user.userCode
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchInsurancePolicyByIdRequest = createAsyncThunk(
  "insurances/fetchInsurancePolicyById",
  async (payload, thunkAPI) => {
    try {
      const response = await Insurance.fetchInsurancePolicyById({
        userId: thunkAPI.getState().account.data.user.userCode,
        coverId: payload,
      });
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createInsuranceClaimRequest = createAsyncThunk(
  "insurances/createInsuranceClaim",
  async (payload, thunkAPI) => {
    try {
      const response = await Insurance.createInsuranceClaim(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchAllInsuranceClaimsRequest = createAsyncThunk(
  "insurances/fetchAllInsuranceClaims",
  async (payload, thunkAPI) => {
    try {
      const response = await Insurance.fetchAllInsuranceClaims(
        thunkAPI.getState().account.data.user.userCode
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const insurancesSlice = createSlice({
  name: "insurances",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllProductCategoriesRequest.fulfilled]: (state, action) => {
      state.insuranceCategories = action.payload.data;
    },
    [fetchAllProductsRequest.fulfilled]: (state, action) => {
      state.insuranceProducts = action.payload.data;
    },
    [fetchAllInsuranceCompaniesRequest.fulfilled]: (state, action) => {
      state.insuranceCompanies = action.payload.data;
    },
    [fetchAllInsurancePoliciesByUserIdRequest.fulfilled]: (state, action) => {
      state.insurancePolicies = action.payload.data;
    },
  },
});

export default insurancesSlice.reducer;
