import React from "react";
import "./styles.scss";
import PageContent from "components/pages/Dashboard/components/PageContent";
import * as MaterialIcon from "react-icons/md";
import {
  Link,
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import EditProfile from "./components/EditProfile";
import Notifications from "./components/Notifications";
import ChangePassword from "./components/ChangePassword";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";

const ProfileSettings = () => {
  const location = useLocation();
  const { path } = useRouteMatch();

  const links = [
    {
      title: "Edit Profile",
      icon: MaterialIcon.MdEdit,
      to: `${path}/edit-profile`,
      component: EditProfile,
    },
    {
      title: "Notifications",
      icon: MaterialIcon.MdNotifications,
      to: `${path}/notifications`,
      component: Notifications,
    },
    {
      title: "Change Password",
      icon: MaterialIcon.MdCompareArrows,
      to: `${path}/change-password`,
      component: ChangePassword,
    },
  ];

  return (
    <PageContent headerTitle="Profile Settings">
      <CSSTransition
        in={true}
        appear={true}
        timeout={300}
        classNames="animate-translate--y"
        unmountOnExit
      >
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-full">
            <div className="flex flex-row w-full min-h-screen">
              <div className="w-4/12 lg:w-3/12 px-10 pt-6 pb-8 border-r border-gray-200">
                {links.map(({ title, icon: Icon, to }) => (
                  <Link
                    key={title}
                    to={to}
                    className={classNames({
                      "flex flex-row justify-start items-center font-semibold text-gray-600 py-2 mb-2 transition ease-in-out duration-300": true,
                      "text-orange-400": location.pathname === to,
                    })}
                  >
                    <Icon size={14} />
                    <span className="ml-5 text-sm">{title}</span>
                  </Link>
                ))}
              </div>

              <div className="flex flex-col w-8/12 lg:w-9/12 px-10 pt-6 pb-8">
                <Switch>
                  {links.map(({ component: PageSectionComponent, to }) => (
                    <Route
                      key={to}
                      exact
                      path={to}
                      component={PageSectionComponent}
                    />
                  ))}
                  <Redirect to={`${path}/edit-profile`} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </PageContent>
  );
};

export default ProfileSettings;
