import * as MaterialIcon from "react-icons/md";
import * as AntIcon from "react-icons/ai";
import * as RemixIcon from "react-icons/ri";

const sidebarItems = [
  {
    title: "Dashboard",
    icon: MaterialIcon.MdDashboard,
    to: "/dashboard",
    activeOnlyWhenExact: true,
  },
  {
    title: "SSLAG",
    icon: MaterialIcon.MdAccountBalance,
    to: "/dashboard/sslag",
    activeOnlyWhenExact: false,
  },
  {
    title: "My Insurance",
    icon: MaterialIcon.MdPlaylistAddCheck,
    to: "/dashboard/insurances",
    activeOnlyWhenExact: false,
  },
  {
    title: "Buy Insurance",
    icon: AntIcon.AiFillPlusCircle,
    to: "/dashboard/buy-insurance",
    activeOnlyWhenExact: false,
  },
  {
    title: "Get a Quote",
    icon: MaterialIcon.MdBookmark,
    to: "/dashboard/get-quote",
    activeOnlyWhenExact: false,
  },
  {
    title: "Insurance Claims",
    icon: MaterialIcon.MdNotifications,
    to: "/dashboard/claims",
    activeOnlyWhenExact: false,
  },
  {
    title: "Profile Settings",
    icon: RemixIcon.RiUserSettingsFill,
    to: "/dashboard/profile/settings",
    activeOnlyWhenExact: false,
  },
];

export default sidebarItems;
