import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Notification } from "services/network";

const initialState = {
  data: [],
};

export const fetchAllNotificationsRequest = createAsyncThunk(
  "notifications/fetchAll",
  async (payload, thunkAPI) => {
    try {
      const { userCode } = thunkAPI.getState().account.data.user;
      const response = await Notification.fetchAllNotifications(userCode);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchNotificationSlotsRequest = createAsyncThunk(
  "notifications/fetchSlots",
  async (payload, thunkAPI) => {
    try {
      const response = await Notification.fetchNotificationSlots(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createNotificationRequest = createAsyncThunk(
  "notifications/create",
  async (payload, thunkAPI) => {
    try {
      const response = await Notification.createNotification(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllNotificationsRequest.fulfilled]: (state, action) => {
      state.data = action.payload.data.rows;
    },
  },
});

export default notificationsSlice.reducer;
