import { combineReducers } from "redux";
import accountReducer from "./slices/account";
import insurancesReducer from "./slices/insurances";
import notificationsReducer from "./slices/notifications";
import filesReducer from "./slices/files";

export default combineReducers({
  account: accountReducer,
  insurances: insurancesReducer,
  notifications: notificationsReducer,
  files: filesReducer,
});
