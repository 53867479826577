import React from "react"
import "./Modal.css"
import { RiCloseLine } from "react-icons/ri"

const Modal = ({ setIsOpen }) => {
  return (
    <>
      <div className='darkBG' onClick={() => setIsOpen(false)} />
      <div className='centered'>
        <div className='modal'>
          <div className='modalHeader'>
            <h5 className='heading'>More Info</h5>
          </div>
          <button className='closeBtn' onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className='modalContent'>
            The SSLAG compulsory Insurance for schools in Lagos State is a
            policy that provides cover for students in the event of death,
            permanent disability or medical expenses as a result of an accident
            while in school or school-related activities.
          </div>
          <div className='modalActions'>
            <div className='actionsContainer'>
              <button className='cancelBtn' onClick={() => setIsOpen(false)}>
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal
