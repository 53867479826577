import axios from "services/api";

export const fetchAllInsuranceCompanies = () => {
  return axios.get("/insurance/company");
};

export const fetchAllProductCategories = () => {
  return axios.get("/insurance/product-catgeory");
};

export const fetchAllProducts = () => {
  return axios.get("/insurance/products");
};

export const fetchProductByBusinessClassId = ({ businessClassId }) => {
  return axios.get(`/insurance/${businessClassId}/products`);
};

export const fetchQuotableProductByBusinessClassId = ({ businessClassId }) => {
  return axios.get(`/insurance/quote/${businessClassId}/products`);
};

export const fetchProductDocumentsByRiskTypeId = ({ riskTypeId }) => {
  return axios.get(`/documents/${riskTypeId}`);
};

export const createInsurancePolicy = (data) => {
  return axios.post(`/user/insurance/create-policy`, data);
};

export const createInsuranceQuote = (data) => {
  return axios.post(`/user/insurance/create-quote`, data);
};

export const fetchAllInsurancePoliciesByUserId = (userId) => {
  return axios.get(`/user/my-insurance/${userId}`);
};

export const fetchInsurancePolicyById = ({ userId, coverId }) => {
  return axios.get(`/user/my-insurance/${userId}/${coverId}`);
};

export const createInsuranceClaim = (data) => {
  return axios.post(`/user/submit-claim`, data);
};

export const fetchAllInsuranceClaims = (userId) => {
  return axios.get(`/user/fetch-claims/${userId}`);
};
