import React, { useEffect, useState } from "react";
import "./styles.scss";
import * as MaterialIcon from "react-icons/md";
import * as FeatherIcon from "react-icons/fi";
import PageContent from "../../Dashboard/components/PageContent";
import { CSSTransition } from "react-transition-group";
import { useDispatch } from "react-redux";
import produce from "immer";
import { fetchAllInsurancePoliciesByUserIdRequest } from "state/slices/insurances";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import moment from "moment";
import ContentSkeleton from "./components/ContentSkeleton";
import EmptyState from "./components/EmptyState";
import { v4 as uuidv4 } from "uuid";

const UserInsuranceList = ({ history }) => {
  const dispatch = useDispatch();

  const perPage = 10;

  const [state, setState] = useState({
    pageId: uuidv4(),
    pageError: "",
    isFetching: false,
    insurancePolicies: {
      searchTerm: "",
      currentPage: 1,
      count: 0,
      rows: [],
    },
  });

  let records = state.insurancePolicies.rows;

  if (state.insurancePolicies.searchTerm) {
    records = records.filter((record) => {
      return `${record.id}` === `${state.insurancePolicies.searchTerm}`;
    });
  }

  const recordStart = (state.insurancePolicies.currentPage - 1) * perPage;
  const numberOfPages = Math.ceil(records.length / perPage);

  const paginatedInsurancePolicies = records.slice(
    recordStart,
    recordStart + perPage
  );

  const handleSearchChange = (e) => {
    setState(
      produce((draft) => {
        draft.insurancePolicies.searchTerm = e.target.value;
      })
    );
  };

  const goToPreviousPage = () => {
    setState(
      produce((draft) => {
        draft.insurancePolicies.currentPage = Math.max(
          draft.insurancePolicies.currentPage - 1,
          1
        );
      })
    );
  };

  const goToNextPage = () => {
    setState(
      produce((draft) => {
        draft.insurancePolicies.currentPage = Math.min(
          draft.insurancePolicies.currentPage + 1,
          Math.ceil(draft.insurancePolicies.rows.length / perPage)
        );
      })
    );
  };

  useEffect(() => {
    fetchAllInsurancePoliciesByUserId().then(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pageKey]);

  const refreshPage = () => {
    setState(
      produce((draft) => {
        draft.pageKey = uuidv4();
        draft.pageError = "";
      })
    );
  };

  const fetchAllInsurancePoliciesByUserId = async () => {
    setState(
      produce((draft) => {
        draft.isFetching = true;
      })
    );

    const resultAction = await dispatch(
      fetchAllInsurancePoliciesByUserIdRequest()
    );

    if (
      fetchAllInsurancePoliciesByUserIdRequest.fulfilled.match(resultAction)
    ) {
      const response = unwrapResult(resultAction);

      setState(
        produce((draft) => {
          draft.isFetching = false;
          draft.insurancePolicies.count = response.data.count;
          draft.insurancePolicies.rows = response.data.rows;
        })
      );
    } else {
      setState(
        produce((draft) => {
          draft.isFetching = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <PageContent
      headerTitle="My Insurance"
      errorMessage={state.pageError}
      onRefresh={refreshPage}
    >
      {state.isFetching ? (
        <div className="flex flex-row w-full pb-8">
          <ContentSkeleton />
        </div>
      ) : (
        <CSSTransition
          in={true}
          appear={true}
          timeout={300}
          classNames="animate-translate--y"
          unmountOnExit
        >
          {state.insurancePolicies.rows.length > 0 ? (
            <div className="flex flex-row w-full pb-8">
              <div className="flex flex-col w-full">
                <div className="flex flex-row flex-wrap justify-end items-center px-10 pt-6">
                  <div className="flex flex-row flex-wrap w-full lg:w-auto mt-5 lg:mt-0 space-x-5">
                    <div className="flex flex-row items-center px-5 py-2 text-sm text-gray-600 bg-gray-200 rounded-md">
                      <span className="mr-8">Filter</span>
                      <FeatherIcon.FiChevronDown size={16} />
                    </div>

                    <div className="flex flex-row relative text-gray-600">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <MaterialIcon.MdSearch size={24} />
                      </span>
                      <input
                        type="text"
                        name="q"
                        className="appearance-none py-2 w-64 text-sm text-gray-600 bg-gray-200 rounded-md pl-10 border focus:outline-none transition ease-in-out duration-300"
                        placeholder="Search Insurance"
                        autoComplete="off"
                        value={state.insurancePolicies.searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-full px-10 pt-6">
                  <div className="align-middle inline-block min-w-full overflow-x-auto bg-white">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="px-2 py-4 bg-gray-100 border-b-2 border-gray-200 font-normal text-left text-sm text-gray-600">
                            #
                          </th>
                          <th className="px-2 py-4 bg-gray-100 border-b-2 border-gray-200 font-normal text-left text-sm text-gray-600">
                            Policy Number
                          </th>
                          <th className="px-2 py-4 bg-gray-100 border-b-2 border-gray-200 font-normal text-left text-sm text-gray-600">
                            Cover Type
                          </th>
                          <th className="px-2 py-4 bg-gray-100 border-b-2 border-gray-200 font-normal text-left text-sm text-gray-600">
                            Risk Type
                          </th>
                          <th className="px-2 py-4 bg-gray-100 border-b-2 border-gray-200 font-normal text-left text-sm text-gray-600">
                            Insurer
                          </th>
                          <th className="px-2 py-4 bg-gray-100 border-b-2 border-gray-200 font-normal text-left text-sm text-gray-600">
                            Premium
                          </th>
                          <th className="px-2 py-4 bg-gray-100 border-b-2 border-gray-200 font-normal text-left text-sm text-gray-600">
                            Status
                          </th>
                          <th className="px-2 py-4 bg-gray-100 border-b-2 border-gray-200 font-normal text-left text-sm text-gray-600">
                            Created At
                          </th>
                          <th className="px-2 py-4 bg-gray-100 border-b-2 border-gray-200 font-normal text-left text-sm text-gray-600">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {paginatedInsurancePolicies.map(
                          (insurancePolicy, index) => (
                            <tr
                              key={index}
                              className="cursor-pointer hover:bg-teal-50 transition ease-in-out duration-100"
                              onClick={() =>
                                history.push(
                                  `/dashboard/insurances/${insurancePolicy.cover_id}/details`
                                )
                              }
                            >
                              <td className="px-2 py-4 whitespace-nowrap border-b text-gray-600 border-gray-200 text-sm">
                                #{insurancePolicy.id}
                              </td>
                              <td className="px-2 py-4 whitespace-nowrap border-b text-gray-600 border-gray-200 text-sm">
                                {insurancePolicy.policy_number || "N/A"}
                              </td>
                              <td className="px-2 py-4 whitespace-nowrap border-b text-gray-600 border-gray-200 text-sm">
                                {(insurancePolicy.businessClassID).toUpperCase() }
                              </td>                              
                              <td className="px-2 py-4 whitespace-nowrap border-b text-gray-600 border-gray-200 text-sm">
                                {/* {insurancePolicy.riskTypeID} */}
                                { insurancePolicy.productDescription}
                              </td>
                              <td className="px-2 py-4 whitespace-nowrap border-b text-gray-600 border-gray-200 text-sm">
                                {/* {insurancePolicy.businessClassID} */}
                                {insurancePolicy.insurer}
                              </td>
                              <td className="px-2 py-4 whitespace-nowrap border-b text-gray-600 border-gray-200 text-sm">
                                N{insurancePolicy.premiumAmount}
                              </td>
                              <td className="px-2 py-4 whitespace-nowrap border-b text-gray-600 border-gray-200 text-sm">
                                {insurancePolicy.paymentStatus !== 0 ? (
                                  <span className="w-full bg-green-500 px-3 py-0.5 font-semibold rounded-sm text-white text-xs">
                                    Paid
                                  </span>
                                ) : (
                                  <span className="w-full bg-red-500 px-3 py-0.5 font-semibold rounded-sm text-white text-xs">
                                    Unpaid
                                  </span>
                                )}
                              </td>
                              <td className="px-2 py-4 whitespace-nowrap border-b text-gray-600 border-gray-200 text-sm">
                                {moment(insurancePolicy.createdAt).format(
                                  "DD/MM/YYYY H:m a"
                                )}
                              </td>
                              <td className="px-2 py-4 whitespace-nowrap border-b text-gray-600 border-gray-200 text-sm">
                                <div className="flex flex-row space-x-4">
                                  <button className="appearance-none text-lg text-gray-400 focus:outline-none hover:text-orange-400 transition ease-in-out duration-300">
                                    <MaterialIcon.MdPrint />
                                  </button>

                                  {insurancePolicy.certificate && (
                                    <a
                                      href={insurancePolicy.certificate}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <button className="appearance-none text-lg text-gray-400 focus:outline-none hover:text-orange-400 transition ease-in-out duration-300">
                                        <MaterialIcon.MdCloudDownload />
                                      </button>
                                    </a>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="inline-flex items-center justify-end space-x-2 pt-8">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center bg-gray-900 text-white font-medium p-1 rounded-md hover:bg-gray-700 focus:outline-none transition ease-in-out duration-300"
                      onClick={goToPreviousPage}
                    >
                      <MaterialIcon.MdChevronLeft />
                    </button>
                    <input
                      type="text"
                      name="page"
                      className="appearance-none py-2 px-2 w-10 text-sm text-center text-gray-900 bg-white rounded-md border border-gray-400 focus:outline-none transition ease-in-out duration-300"
                      placeholder=""
                      value={state.insurancePolicies.currentPage}
                      autoComplete="off"
                      onChange={() => null}
                    />
                    <button
                      type="button"
                      className="inline-flex items-center justify-center bg-gray-900 text-white font-medium p-1 rounded-md hover:bg-gray-700 focus:outline-none transition ease-in-out duration-300"
                      onClick={goToNextPage}
                    >
                      <MaterialIcon.MdChevronRight />
                    </button>
                    <span className="text-sm text-gray-900">
                      of {numberOfPages} pages
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-row w-full h-full pb-8">
              <EmptyState />
            </div>
          )}
        </CSSTransition>
      )}
    </PageContent>
  );
};

export default UserInsuranceList;
