import React, { useEffect, useState } from "react";
import * as MaterialIcon from "react-icons/md";
import { CSSTransition } from "react-transition-group";
import { useDispatch } from "react-redux";
import produce from "immer";
import { fetchAllNotificationsRequest } from "state/slices/notifications";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment";
import { Link } from "react-router-dom";

const Notifications = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    isFetching: false,
    insuranceQuotes: [],
  });

  useEffect(() => {
    fetchAllNotifications().then(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllNotifications = async () => {
    setState(
      produce((draft) => {
        draft.isFetching = true;
      })
    );

    const resultAction = await dispatch(fetchAllNotificationsRequest());

    if (fetchAllNotificationsRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);

      setState(
        produce((draft) => {
          draft.isFetching = false;
          draft.insuranceQuotes = response.data.rows;
        })
      );
    } else {
      setState(
        produce((draft) => {
          draft.isFetching = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <CSSTransition
      in={true}
      appear={true}
      timeout={300}
      classNames="animate-translate--y"
      unmountOnExit
    >
      <div>
        <span className="text-xl text-gray-900 font-bold">Notifications</span>
        <div className="w-full xl:w-3/5 mt-5">
          <div className="flex flex-col">
            <span className="text-sm text-gray-400">Insurance Quotes</span>
            <div className="flex flex-col">
              {state.insuranceQuotes.map((insuranceQuote) => (
                <Link
                  key={insuranceQuote.cover_id}
                  to={`/dashboard/insurances/${insuranceQuote.cover_id}/details`}
                >
                  <div className="flex flex-row justify-between items-center mt-3">
                    <div className="flex flex-row justify-start items-center">
                      <div className="relative inline-block w-auto">
                        <div className="inline-flex justify-center items-center bg-orange-400 text-white w-8 h-8 rounded-full">
                          <MaterialIcon.MdNotifications size={20} />
                        </div>
                        <span className="inline-flex justify-center items-center absolute top-0 right-0 bg-teal-400 w-2.5 h-2.5 rounded-full" />
                      </div>
                      <span className="text-gray-700 text-sm font-semibold ml-3">
                       QUOTE APPROVED - make payment
                      </span>
                    </div>
                    <span className="text-gray-400 text-sm ml-3">
                      {moment(insuranceQuote.updatedAt).fromNow()}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Notifications;
