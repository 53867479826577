import React from "react";

const ActivitiesTableSkeleton = () => (
  <div className="flex flex-col w-full">
    <div className="flex flex-row flex-wrap justify-start items-center px-10 pt-6">
      <div className="flex flex-row flex-wrap w-full lg:w-auto mt-5 lg:mt-0 space-x-5">
        <div className="flex flex-row w-28 h-6 items-center px-5 py-2 bg-gray-200 rounded-sm animate-pulse" />
      </div>
    </div>

    <div className="flex flex-col w-full px-10 pt-6">
      <div className="flex-col w-full bg-white space-y-2">
        <div className="flex flex-row w-full h-12 items-center px-5 py-2 bg-gray-200 rounded-sm border-b-2 border-gray-300 animate-pulse" />
        <div className="flex flex-row w-full h-12 items-center px-5 py-2 bg-gray-200 rounded-sm animate-pulse" />
        <div className="flex flex-row w-full h-12 items-center px-5 py-2 bg-gray-200 rounded-sm animate-pulse" />
        <div className="flex flex-row w-full h-12 items-center px-5 py-2 bg-gray-200 rounded-sm animate-pulse" />
        <div className="flex flex-row w-full h-12 items-center px-5 py-2 bg-gray-200 rounded-sm animate-pulse" />
      </div>
    </div>
  </div>
);

export default ActivitiesTableSkeleton;
