import React, { useEffect, useState } from "react";
import "./styles.scss";
import PageContent from "../../Dashboard/components/PageContent";
import { CSSTransition } from "react-transition-group";
import Chart from "react-apexcharts";
import { useDispatch } from "react-redux";
import produce from "immer";
import { fetchAllActivitiesRequest } from "state/slices/activities";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import moment from "moment";
import ActivitiesTableSkeleton from "./components/ActivitiesTableSkeleton";
import SummaryChartSkeleton from "./components/SummaryChartSkeleton";

const ChartLegendItem = ({ symbol, title, backgroundColor }) => (
  <div className="flex flex-row justify-start items-center">
    <div
      className={`flex flex-row justify-center items-center h-6 w-6 p-2 rounded-lg bg-${
        backgroundColor || "orange-400"
      } text-white mr-5`}
    >
      {symbol}
    </div>
    <div className="text-sm text-gray-500 font-semibold">{title}</div>
  </div>
);

const DashboardHome = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    isFetching: false,
    activities: [],
  });

  const [summaryChart, setSummaryChart] = useState({
    options: {
      chart: {
        id: "activities-summary-chart",
        animations: {
          speed: 200,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          distributed: true,
          columnWidth: "85%",
        },
      },
      colors: ["#21C55D", "#F88A0C", "#F04444", "#5FEAD3"],
      xaxis: {
        categories: ["a", "x", "o", "n"],
        labels: {
          style: {
            fontSize: "16px",
            colors: ["#CCCCCC", "#CCCCCC", "#CCCCCC", "#CCCCCC"],
            fontFamily: "Helvetica, Arial, sans-serif",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        show: false,
      },
    },
    series: [
      {
        name: "Summary",
        data: [0, 0, 0, 0],
      },
    ],
  });

  useEffect(() => {
    fetchAllActivities().then(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllActivities = async () => {
    setState(
      produce((draft) => {
        draft.isFetching = true;
      })
    );

    const resultAction = await dispatch(fetchAllActivitiesRequest());

    if (fetchAllActivitiesRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);

      const insurancePoliciesCount =
        response.data.filter((item) => item.type === "1").length || 0;

      const insuranceQuotesCount =
        response.data.filter((item) => item.type === "2").length || 0;

      const paidInsurancePoliciesCount =
        response.data.filter((item) => item.paymentStatus === 1).length || 0;

      const unpaidInsurancePoliciesCount =
        response.data.filter((item) => item.paymentStatus === 0).length || 0;

      setState(
        produce((draft) => {
          draft.isFetching = false;
          draft.activities = response.data;
        })
      );

      setSummaryChart(
        produce((draft) => {
          draft.series[0].data = [
            paidInsurancePoliciesCount,
            unpaidInsurancePoliciesCount,
            insurancePoliciesCount,
            insuranceQuotesCount,
          ];
        })
      );
    } else {
      setState(
        produce((draft) => {
          draft.isFetching = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <PageContent headerTitle="Dashboard">
      {state.isFetching ? (
        <div className="flex flex-col">
          <div className="flex flex-row w-full mt-6">
            <SummaryChartSkeleton />
          </div>

          <div className="flex flex-row w-full pb-8 mt-6">
            <ActivitiesTableSkeleton />
          </div>
        </div>
      ) : (
        <CSSTransition
          in={true}
          appear={true}
          timeout={300}
          classNames="animate-translate--y"
          unmountOnExit
        >
          <div className="flex flex-col">
            <div className="flex flex-row w-full mt-6">
              <div className="flex flex-col w-full">
                <div className="flex flex-row flex-wrap justify-between items-center px-10">
                  <div className="flex flex-row w-full lg:w-auto space-x-5">
                    <span className="text-lg font-semibold whitespace-nowrap">
                      Summary
                    </span>
                  </div>
                </div>

                <div className="flex flex-row w-full px-10 py-6">
                  <div className="flex flex-row justify-center items-start w-3/5">
                    <Chart
                      className="w-full h-12"
                      options={summaryChart.options}
                      series={summaryChart.series}
                      type="bar"
                      height={250}
                    />
                  </div>
                  <div className="flex flex-row justify-center items-center w-2/5">
                    <div className="flex flex-col justify-center items-start space-y-3">
                      <ChartLegendItem
                        backgroundColor="green-500"
                        symbol="a"
                        title="Paid"
                      />
                      <ChartLegendItem
                        backgroundColor="orange-400"
                        symbol="x"
                        title="Unpaid"
                      />
                      <ChartLegendItem
                        backgroundColor="red-500"
                        symbol="o"
                        title="All policies"
                      />
                      <ChartLegendItem
                        backgroundColor="teal-300"
                        symbol="n"
                        title="Quotes required"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row w-full pb-8 mt-6">
              <div className="flex flex-col w-full">
                <div className="flex flex-row flex-wrap justify-between items-center px-10">
                  <div className="flex flex-row w-full lg:w-auto space-x-5">
                    <span className="text-lg font-semibold whitespace-nowrap">
                      Activity Log
                    </span>
                  </div>
                </div>

                <div className="flex flex-row w-full px-10 py-6">
                  <div className="align-middle inline-block min-w-full overflow-x-auto bg-white">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="px-2 py-4 whitespace-nowrap bg-gray-100 border-b-2 border-gray-200 font-normal text-left text-sm text-gray-600">
                            S/N
                          </th>
                          <th className="px-2 py-4 whitespace-nowrap bg-gray-100 border-b-2 border-gray-200 font-normal text-left text-sm text-gray-600">
                            Customer Name
                          </th>
                          <th className="px-2 py-4 whitespace-nowrap bg-gray-100 border-b-2 border-gray-200 font-normal text-left text-sm text-gray-600">
                            Policy Start Date
                          </th>
                          <th className="px-2 py-4 whitespace-nowrap bg-gray-100 border-b-2 border-gray-200 font-normal text-left text-sm text-gray-600">
                            Policy End Date
                          </th>
                          <th className="px-2 py-4 whitespace-nowrap bg-gray-100 border-b-2 border-gray-200 font-normal text-left text-sm text-gray-600">
                            Payment Status
                          </th>
                          <th className="px-2 py-4 whitespace-nowrap bg-gray-100 border-b-2 border-gray-200 font-normal text-left text-sm text-gray-600">
                            Created At
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {state.activities.map((item, index) => (
                          <tr key={index}>
                            <td className="px-2 py-4 whitespace-nowrap border-b text-gray-600 border-gray-200 text-sm">
                              #{index + 1}
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap border-b text-gray-600 border-gray-200 text-sm">
                              {`${item.firstname} ${item.lastname}`}
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap border-b text-gray-600 border-gray-200 text-sm">
                              {moment(item.policyStartDate).format("lll")}
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap border-b text-gray-600 border-gray-200 text-sm">
                              {moment(item.policyEndDate).format("lll")}
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap border-b text-gray-600 border-gray-200 text-sm">
                              {item.paymentStatus !== 1 ? (
                                <span className="bg-red-500 px-3 py-0.5 font-semibold rounded-sm text-white text-xs">
                                  Inactive
                                </span>
                              ) : (
                                <span className="bg-green-500 px-3 py-0.5 font-semibold rounded-sm text-white text-xs">
                                  Paid
                                </span>
                              )}
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap border-b text-gray-600 border-gray-200 text-sm">
                              {moment(item.createdAt).format(
                                "DD/MM/YYYY H:m a"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
    </PageContent>
  );
};

export default DashboardHome;
