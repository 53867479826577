import React, { useLayoutEffect, useState } from "react";
import "./styles.scss";
import Logo from "assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { resetPasswordRequest } from "state/slices/account";
import { Field, Form, Formik } from "formik";
import produce from "immer";
import TextInput from "components/shared/forms/TextInput";
import Button from "components/shared/forms/Button";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

const StyledLogo = styled.img`
  width: 120px;
`;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  useLayoutEffect(() => {
    document.title = "Africover - Reset Password";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [state, setState] = useState({
    isResetPasswordLoading: false,
  });

  const initialValues = {
    password: "",
    passwordConfirmation: "",
  };

  const validationSchema = yup.object().shape({
    password: yup.string().label("Password").required(),
    passwordConfirmation: yup
      .string()
      .label("Password confirmation")
      .oneOf([yup.ref("password"), null], "Password confirmation must match")
      .required(),
  });

  const handleResetPassword = async (formValues, formikHelpers) => {
    const formPayload = {
      email: query.get("email"),
      code: query.get("code"),
      newPassword: formValues.password,
      confirm_newPassword: formValues.passwordConfirmation,
    };

    setState(
      produce((draft) => {
        draft.isResetPasswordLoading = true;
      })
    );

    const resultAction = await dispatch(resetPasswordRequest(formPayload));

    if (resetPasswordRequest.fulfilled.match(resultAction)) {
      formikHelpers.resetForm();

      setState(
        produce((draft) => {
          draft.isResetPasswordLoading = false;
        })
      );

      toast.success(
        resultAction.payload?.message || "Account activation code resent."
      );
    } else {
      setState(
        produce((draft) => {
          draft.isResetPasswordLoading = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-800 justify-start md:justify-center items-center font-nunito-sans">
      <CSSTransition
        in={true}
        appear={true}
        timeout={300}
        classNames="animate-translate--y"
        unmountOnExit
      >
        <div className="flex flex-col w-full p-3 md:p-0 md:w-2/3 lg:w-1/3 mt-10 md:mt-8 mb-16 md:mb-10">
          <div className="flex flex-row justify-start w-full">
            <Link to="/auth/login">
              <StyledLogo src={Logo} alt="" />
            </Link>
          </div>
          <div className="p-6 md:p-10 bg-white shadow rounded-xl mt-5">
            <h1 className="text-gray-900 text-lg font-bold">Reset Password</h1>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleResetPassword}
            >
              {({ handleSubmit, isValid }) => {
                return (
                  <Form className="w-full mt-5" onSubmit={handleSubmit}>
                    <div className="flex flex-col">
                      <label
                        htmlFor="password"
                        className="text-sm text-gray-600 font-semibold"
                      >
                        Password
                      </label>
                      <Field
                        component={TextInput}
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                      />
                    </div>

                    <div className="flex flex-col mt-5">
                      <label
                        htmlFor="passwordConfirmation"
                        className="text-sm text-gray-600 font-semibold"
                      >
                        Password
                      </label>
                      <Field
                        component={TextInput}
                        type="password"
                        name="passwordConfirmation"
                        id="passwordConfirmation"
                        placeholder="Confirm Password"
                      />
                    </div>

                    <div className="flex flex-col mt-5">
                      <Button
                        type="submit"
                        title="Reset Password"
                        loadingTitle="Resetting password..."
                        variant="primary"
                        width="full"
                        loading={state.isResetPasswordLoading}
                        disabled={!isValid}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>

            <div className="flex flex-row pt-5 justify-center">
              <span className="text-gray-600 text-sm">
                You did notification forget my password?{" "}
                <Link
                  to="/auth/login"
                  className="text-teal-400 hover:text-teal-500 transition ease-in-out duration-300"
                >
                  LOGIN
                </Link>
              </span>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default ResetPassword;
