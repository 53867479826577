import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Activity } from "services/network";

const initialState = {
  data: [],
};

export const fetchAllActivitiesRequest = createAsyncThunk(
  "activities/fetchAll",
  async (payload, thunkAPI) => {
    try {
      const { userCode } = thunkAPI.getState().account.data.user;
      const response = await Activity.fetchAllActivities(userCode);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {},
});

export default activitiesSlice.reducer;
