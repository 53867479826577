import React, { Fragment, useContext } from "react";
import { Field, useFormikContext } from "formik";
import Button from "components/shared/forms/Button";
import { CreateInsuranceContext } from "contexts/CreateInsuranceContext";
import FileInput from "components/shared/forms/FileInput";
import _ from "lodash";

const UploadDocumentsFormSection = () => {
  const formikContext = useFormikContext();
  const {
    insuranceDocuments,
    currentStep,
    lastStep,
    nextStep,
    previousStep,
  } = useContext(CreateInsuranceContext);

  const documents = insuranceDocuments.filter((item) => item.type === "file");
  const chunkedInsuranceDocuments = _.chunk(documents, 3);

  return (
    <Fragment>
      {chunkedInsuranceDocuments.map((documents, index) => (
        <div
          key={index}
          className={`-mx-3 lg:flex mt-0 lg:mt-${index === 0 ? "0" : "6"}`}
        >
          {documents.map((document, index) => (
            <div key={index} className="lg:w-1/3 px-3 mt-6 lg:mt-0">
              <div className="flex flex-col">
                <label
                  htmlFor="title"
                  className="text-sm text-gray-600 font-semibold"
                >
                  {document.label}
                </label>
                <Field
                  key={document.field}
                  component={FileInput}
                  type={document.type}
                  name={document.field}
                  id={document.field}
                  placeholder="Please enter corresponding information"
                  onChange={(event) => {
                    formikContext.setFieldValue(
                      document.field,
                      event.currentTarget.files[0]
                    );
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      ))}

      <div className="-mx-3 lg:flex mt-0 lg:mt-8">
        <div className="flex flex-row justify-end lg:w-full px-3 mt-8 lg:mt-0 space-x-5">
          <Button
            type="button"
            title="Previous"
            variant="dark"
            size="md"
            width="auto"
            disabled={currentStep === 0}
            onClick={previousStep}
          />
          <Button
            type="button"
            title={currentStep === lastStep ? "Submit" : "Next"}
            variant="primary"
            size="md"
            width="auto"
            disabled={!formikContext.isValid}
            loading={formikContext.isSubmitting}
            onClick={() => nextStep(formikContext)}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default UploadDocumentsFormSection;
