import React, { useContext } from "react";
import classNames from "classnames";
import Logo from "assets/images/logo.png";
import NavMenuItem from "./NavMenuItem";
import { PageContext } from "components/providers/PageContext";
import sidebarItems from "config/sidebarItems";

const Sidebar = () => {
  const { showSidebar, closeSidebar } = useContext(PageContext);

  return (
    <nav
      className={classNames({
        "sidebar-close": !showSidebar,
        "shadow-sm md:shadow-none sidebar-open": showSidebar,
        "flex justify-start flex-col fixed bg-gray-800 w-72 h-full z-50": true,
      })}
    >
      <div className="mt-6">
        <a href="/#">
          <img
            src={Logo}
            alt=""
            className="rounded-md w-38 px-0 border-0 border-gray-200"
          />
        </a>
      </div>
      <div className="mt-12 mb-10">
        <ul>
          {sidebarItems.map((item, index) => (
            <NavMenuItem key={index} item={item} onClick={closeSidebar} />
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
